import React, { useState, useEffect } from 'react';
import { Table, Popconfirm, message, Button, Spin, Upload, Space } from 'antd';
import { Pie, G2 } from '@ant-design/plots';
import { UploadOutlined } from '@ant-design/icons'
import { EsmUtilityService } from '../../services'

export const BugReportMonitoringPage = (props) => {

    const [selectedFiles, setSelectedFiles] = useState([])
    const [data, setStorageInfo] = useState([])
    const [files, setFiles] = useState([])
    const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false)
    const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false)
    const [isPageLoading, setIsPageLoading] = useState(true)
    const env = 'BugReport'

    const columns = [
        {
          title: 'File Id',
          dataIndex: 'id',
        },
        {
          title: 'File Name',
          dataIndex: 'name',
        },
        {
          title: 'File Type',
          dataIndex: 'mimeType',
        },
      ]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedFiles(selectedRowKeys)
            if (selectedRowKeys.length > 0){
              setIsDeleteButtonEnabled(true)
            }
            else{
              setIsDeleteButtonEnabled(false)
            }
        },
        getCheckboxProps: (record) => ({
          disabled: record.name === 'EmBugReport',
          // Column configuration not to be checked
          name: record.name,
        }),
    }

    const G = G2.getEngine('canvas');
    
    const cfg = {
        appendPadding: 10,
        data,
        angleField: 'count',
        colorField: 'type',
        radius: 0.75,
        legend: false,
        label: {
        type: 'spider',
        labelHeight: 40,
        formatter: (data, mappingData) => {
            const group = new G.Group({});
            group.addShape({
            type: 'circle',
            attrs: {
                x: 0,
                y: 0,
                width: 40,
                height: 50,
                r: 5,
                fill: mappingData.color,
            },
            });
            group.addShape({
            type: 'text',
            attrs: {
                x: 10,
                y: 8,
                text: `${data.type}`,
                fill: mappingData.color,
            },
            });
            group.addShape({
            type: 'text',
            attrs: {
                x: 0,
                y: 25,
                text: `${(data.count).toFixed(2)}GB (${(data.percent * 100).toFixed(2)}%)`,
                fill: 'rgba(0, 0, 0, 0.65)',
                fontWeight: 700,
            },
            });
            return group;
        },
        },
        interactions: [
        {
            type: 'element-selected',
        },
        {
            type: 'element-active',
        },
        ],
    }
  const config = cfg

  async function RefreshPageData(){
    var storageInfo = await EsmUtilityService.getStorageInfo(env)
    setStorageInfo(storageInfo?.result)

    var files = await EsmUtilityService.getFiles(env)
    setFiles(files?.result?.files)
  }
  
  async function onDeleteFilesClicked(){
      if(selectedFiles != null && selectedFiles.length > 0){
        setIsDeleteButtonEnabled(false)
        setIsDeleteButtonLoading(true)
        var isSucces = await EsmUtilityService.deleteFiles(env, selectedFiles)

        if(isSucces){
          await RefreshPageData()
          message.success('File deleted!');
        }

        setIsDeleteButtonLoading(false)
      }
  }

  const uploadProps = {
    name: 'file',
      action: 'api/EsmUtility/uploadFile?env=BugReport',
    headers: {
      authorization: 'authorization-text',
    },
  
    async onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
  
      if (info.file.status === 'done') {
        await RefreshPageData()
        message.success(`${info.file.name} file uploaded successfully`);

      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  }

  useEffect(() => {
    const getPageInfo = async () => {
      setIsPageLoading(true)
      await RefreshPageData()
      setIsPageLoading(false)
    }
    getPageInfo()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
    <>
      <Spin spinning={isPageLoading} tip="Loading..." size='large'>
          <Pie {...config} />

          <Table
              rowSelection={{
              type: "checkbox",
              ...rowSelection,
              }}
              columns={columns}
              dataSource={files}
              pagination={{
                  pageSize: 50,
                  showSizeChanger: false
              }}
              size="small"
          />

          <Space direction="vertical" size="middle">
            <Popconfirm
                title={"Are you sure to delete " + selectedFiles.length + " selected files"}
                onConfirm={onDeleteFilesClicked}
                okText="Yes"
                cancelText="No"
            >
                <Button type="primary" danger loading={isDeleteButtonLoading} disabled={!isDeleteButtonEnabled}>Delete Files</Button>
            </Popconfirm>

            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Space>
      </Spin>
    </>)
}

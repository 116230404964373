import React from 'react'
import { Tag } from 'antd'
import { Pie, G2 } from '@ant-design/plots';

export const EsmPieChart = (props) => {
    var data = props.GraphData ?? []
    const G = G2.getEngine('canvas');
      // const config = {
      //   appendPadding: 10,
      //   data,
      //   angleField: 'count',
      //   colorField: 'type',
      //   radius: 0.8,
      //   label: {
      //     type: 'outer',
      //     content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      //   },
      //   interactions: [
      //     {
      //       type: 'element-active',
      //     },
      //   ],
      // }

      const cfg = {
        appendPadding: 10,
        data,
        angleField: 'count',
        colorField: 'type',
        radius: 0.75,
        legend: false,
        label: {
          type: 'spider',
          labelHeight: 40,
          formatter: (data, mappingData) => {
            const group = new G.Group({});
            group.addShape({
              type: 'circle',
              attrs: {
                x: 0,
                y: 0,
                width: 40,
                height: 50,
                r: 5,
                fill: mappingData.color,
              },
            });
            group.addShape({
              type: 'text',
              attrs: {
                x: 10,
                y: 8,
                text: `${data.type}`,
                fill: mappingData.color,
              },
            });
            group.addShape({
              type: 'text',
              attrs: {
                x: 0,
                y: 25,
                text: `${(data.percent * 100).toFixed(2)}%`,
                fill: 'rgba(0, 0, 0, 0.65)',
                fontWeight: 700,
              },
            });
            return group;
          },
        },
        interactions: [
          {
            type: 'element-selected',
          },
          {
            type: 'element-active',
          },
        ],
      }

    const config = cfg

  return (
      <>
        <Tag color="cyan">{props.GraphTitle}</Tag>
        <Pie {...config} />
      </>

  )
}
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import moment from 'moment'

import { OddsEngineSettingService } from '../../services'

export const OddsEngineSettingListPage = () => {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    useEffect(() => {
        const getOddsEngineInstances = async () => {
            const response = await OddsEngineSettingService.getAll()
            setData(response?.result ?? [])
            setLoading(false)
        }
        getOddsEngineInstances()
      }, [])

    const columns = [
        {
            title: 'Instance Name',
            dataIndex: 'instanceName',
            key: 'instanceName',
            sorter: (a, b) => a.instanceName?.localCompare(b.instanceName),
            render: (id) => <Link to={`/oddsEngine/setting/${id}`}>{id}</Link>
        },
        {
            title: 'Is Active',
            key: 'status',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (value) => (value === 'Active'? <CheckCircleFilled style={{ color: '#52c41a', fontSize: '18px' }} /> : <CloseCircleFilled style={{ color: '#8c8c8c', fontSize: '18px' }} />)
        },
        {
            title: 'Updated Date',
            dataIndex: 'updatedTimestamp',
            key: 'updatedTimestamp',
            sorter: (a, b) => new Date(a.updatedTimestamp) - new Date(b.updatedTimestamp),
            render: (value) => {
                const date = moment(value)
                return date.isValid() ? date.format('YYYY-MM-DD hh:mm A') : ''
            }
        }
    ]

    return (
        <>
            <Table rowKey="id" columns={columns} dataSource={data} size="small" loading={loading} bordered />
        </>
    )
}

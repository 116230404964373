import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { PageHeader, Table, Tag, Button } from 'antd'
import { PlusOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import moment from 'moment'

import { UserService } from '../../services'

export const UserListPage = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => a.fullName?.localeCompare(b.fullName),
      render: (text, row) => <Link to={`/users/${row.id}`}>{text}</Link>
    },
    {
      title: 'Roles',
      key: 'roles',
      dataIndex: 'roles',
      render: (roles) => (
        <>
          {roles.map((role) => (
            <Tag color={role === 'Administrator' ? 'cyan' : 'green'} key={role}>
              {role.toUpperCase()}
            </Tag>
          ))}
        </>
      )
    },
    {
      title: 'Active',
      key: 'isAvtive',
      dataIndex: 'isActive',
      sorter: (a, b) => a.isActive - b.isActive,
      render: (isActive) => (isActive ? <CheckCircleFilled style={{ color: '#52c41a', fontSize: '18px' }} /> : <CloseCircleFilled style={{ color: '#8c8c8c', fontSize: '18px' }} />)
    },
    {
      title: 'Remarks',
      key: 'remarks',
      dataIndex: 'remarks',
      sorter: (a, b) => a.remarks?.localeCompare(b.remarks)
    },
    {
      title: 'Last Login Date',
      dataIndex: 'lastLoginDate',
      key: 'lastLoginDate',
      sorter: (a, b) => new Date(a.lastLoginDate) - new Date(b.lastLoginDate),
      render: (value) => {
        const date = moment(value)
        return date.isValid() ? date.format('YYYY-MM-DD hh:mm A') : ''
      }
    },
    {
      title: '',
      key: 'action',
      render: (_, row) => (
        <Link to={`/users/resetpassword/${row.id}`}>
          <Button type="link">Reset Password</Button>
        </Link>
      )
    }
  ]

  useEffect(() => {
    const getUsers = async () => {
      const response = await UserService.getAll()
      setData(response?.result ?? [])
      setLoading(false)
    }
    getUsers()
  }, [])

  return (
    <>
      <PageHeader
        extra={[
          <Link key="new-user-link" to="/users/new">
            <Button key="1" icon={<PlusOutlined />} type="primary">
              New
            </Button>
          </Link>
        ]}
      />
      <Table rowKey="id" columns={columns} dataSource={data} size="small" loading={loading} bordered />
    </>
  )
}

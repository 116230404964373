import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Form, Input, Space, Button, Popconfirm, Layout, PageHeader, Tooltip, Select, notification } from 'antd'
import { QuestionCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons'
import { formItemLayout } from '../../FormLayout'

import { EsmUserService } from '../../services'

export const EsmUserEditorPage = (props) => {
    const [userRoles, setUserRoles] = useState([])
    const [isNewUser, setIsNewUser] = useState(true)
    const [userForm] = Form.useForm()
    const [saving, setSaving] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const { Content } = Layout;
    const { Option } = Select

    let { id } = useParams()
    const userId = props.location.state?.userId
    var listingPageUrl = `/esmUsers/users?userId=${userId}`

    useEffect(() => {
        const getUserRoles = async () => {
            const response = await EsmUserService.getUserRoles()
            //if (response.isError) showErrorMessage(response.message)
            setUserRoles(response.result)
        }

        const getUser = async () => {
            let data = {};
            if (id) {
                setIsNewUser(false)
                const response = await EsmUserService.get(id)
                if (response.isError) showErrorMessage(response.message)
                data = response?.result
            }
            userForm.setFieldsValue(data)
        }
        getUserRoles()
        getUser()
    }, [id, userForm])

    const showSuccessMessage = (message) => notification['success']({ message: message, placement: 'bottomRight' })
    const showErrorMessage = (message) => notification['error']({ message: message, placement: 'bottomRight' })

    const handleFormSubmit = async (user) => {
        setSaving(true)
        user.TriggeredUserId = userId
        if (id) {
            let result = await EsmUserService.updateUser(user)
            if (result.isError) showErrorMessage(`Failed to update User. ${result.message}`)
            else showSuccessMessage('User successfully updated.')
        } else {
            let result = await EsmUserService.create(user)
            if (result.isError) showErrorMessage(`Failed to create User. ${result.message}`)
            else showSuccessMessage('User successfully created.')
        }
        setSaving(false)
        props.history.push(listingPageUrl)
    }

    const handleDelete = async () => {
        setDeleting(true)
        let result = await EsmUserService.updateUserStatus({id: id})
        if (result.isError) showErrorMessage(result?.data?.message ?? 'Failed to delete User.')
        else showSuccessMessage('User successfully deleted.')
        setDeleting(false)
        props.history.push(listingPageUrl)
    }

    const infoContent = () => {
        return (
            <>
                <Tooltip title="prompt text">
                    <span><b>Supervisor:</b> Able to perform all actions <br /></span>
                    <span><b>Team Leader:</b> Able to approve widget action and manage users <br /></span>
                    <span><b>PIC:</b> Able to approve widget action only</span>
                </Tooltip>
            </>
        )
    }

    const EsmUserEditorForm = () => {
        return (
            <>
                < Form {...formItemLayout} form={userForm} onFinish={handleFormSubmit} autoComplete="off" >
                    {!isNewUser && (
                        <Form.Item name="id" hidden={true}>
                            <Input />
                        </Form.Item>
                    )
                    }
                    <Form.Item label="Username" name="username" rules={[{ required: true }]}>
                        <Input disabled={!isNewUser} />
                    </Form.Item>
                    {
                        isNewUser ? (
                            <>
                                <Form.Item label="Password" name="password" rules={[
                                    { required: true },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value !== null && value.length < 4) {
                                                return Promise.reject(new Error('Password must contain a minimum of 4 characters'))
                                            }
                                            return Promise.resolve()
                                        }
                                    })
                                    ]}
                                >
                                    <Input.Password autoComplete="new-password" />
                                </Form.Item>
                                <Form.Item
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    dependencies={['password']}
                                    rules={[
                                        { required: true },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve()
                                                }
                                                if (value !== null && value.length < 4) {
                                                    return Promise.reject(new Error('Password must contain a minimum of 4 characters'))
                                                }
                                                return Promise.reject(new Error('The Password and Confirm Password do not match!'))
                                            }
                                        })
                                    ]}
                                >
                                    <Input.Password autoComplete="new-password" />
                                </Form.Item>
                            </>
                        ) : null
                    }
                    <Form.Item label="Role" name="role" rules={[{ required: true }]} tooltip={infoContent()} hidden={!isNewUser}>
                        <Select placeholder={'Select a role'} allowClear>
                            {Object.entries(userRoles).map(([key, value]) => (
                                <Option value={value} key={key}>
                                    {value}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Remark" name="remark">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item label="&nbsp;" colon={false}>
                        <Space>
                            <Button type="primary" htmlType="submit" loading={saving}>
                                Save
                            </Button>
                            <Link to={listingPageUrl}>
                                <Button type="default">Cancel</Button>
                            </Link>
                            {!isNewUser && (
                                <Popconfirm placement="top" title="Are you sure you want to delete?" onConfirm={handleDelete} icon={<QuestionCircleOutlined style={{ color: 'red' }} />} okText="Yes" cancelText="No">
                                    <Button type="danger" loading={deleting}>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            )}
                        </Space>
                    </Form.Item>
                </Form >
            </>)
    }

    return (
        <>
            <PageHeader title={id === undefined ? "ESM New User" : "ESM Edit User"} />
            <Content style={{
                margin: '20px',
            }}>
                <EsmUserEditorForm/>
            </Content>
        </>
    )
}

import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'

import ViewDashboard from '@2fd/ant-design-icons/lib/ViewDashboard'
import AccountGroup from '@2fd/ant-design-icons/lib/AccountGroup'
import Key from '@2fd/ant-design-icons/lib/Key'
import Soccer from '@2fd/ant-design-icons/lib/Soccer'
import Package from '@2fd/ant-design-icons/lib/PackageVariantClosed'
import SendMail from '@2fd/ant-design-icons/lib/EmailSendOutline'
import ViewGridOutline from '@2fd/ant-design-icons/lib/ViewGridOutline'
import EngineOutline from '@2fd/ant-design-icons/lib/EngineOutline'
import Help from '@2fd/ant-design-icons/lib/Help'
import logo from '../../logo.svg'
import './Sidebar.less'

const { Sider } = Layout

export const Sidebar = () => {
  const location = useLocation()
  const selectedRoute = location.pathname || '/'
  const [collapsed, setCollapsed] = useState(false)
  const onCollapse = () => setCollapsed(!collapsed)

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem(<Link to="/">Dashboard</Link>, '/', <ViewDashboard />),
    getItem('ESM Report', '/esm', <Soccer />, [
      getItem(<Link to="/esm/detail">Detail Report</Link>, '/esm/detail'),
      getItem(<Link to="/esm/summary">Summary Report</Link>, '/esm/summary')
    ]),
    getItem('ESM Package', '/esmPackage', <Package />, [
      getItem(<Link to="/esmPackage/package">Package Histories</Link>, '/esmPackage/pacakge'),
      getItem(<Link to="/esmPackage/version">Latest Package Info</Link>, '/esmPackage/version')
    ]),
    getItem(<Link to="/messenger">ESM Request Log</Link>, '/messenger', <SendMail />),
    getItem('ESM Utilities', '/esmUtilities', <ViewGridOutline />, [
      getItem(<Link to="/esmUtilities/bugreportmonitoring">Bug Report Monitoring</Link>, '/esmUtilities/bugreportmonitoring'),
    ]),
    getItem(<Link to="/esmUsers/users?userId=1">ESM Users</Link>, '/esmUsers', <AccountGroup />),
    getItem(<Link to="/esm/help">ESM Help</Link>, '/esm/help', <Help />),
    getItem('Odds Engine', '/oddsEngine', <EngineOutline />,  [
      getItem(<Link to="/oddsEngine/setting">Odds Engine Setting</Link>, '/oddsEngine/setting'),
      getItem(<Link to="/oddsEngine/monitoring">Odds Engine Monitoring</Link>, '/oddsEngine/monitoring'),
    ]),
    getItem(<Link to="/users">Users</Link>, '/users', <AccountGroup />),
    getItem(<Link to="/apikeys">API Keys</Link>, '/apikeys', <AccountGroup />)
  ]

  return (
    <Sider collapsible collapsedWidth={64} collapsed={collapsed} onCollapse={onCollapse}>
      <div className="app-logo">
        <img src={logo} alt="App Logo" />
      </div>
      <Menu
        selectedKeys={[selectedRoute]}
        mode="inline"
        items={items}
      />

    </Sider>
  )
}

import axios from 'axios'

const getSearchCriteria = async () => {
  var response = await axios.get('ActionLog/searchCriteria').catch(console.log)
  return response?.data
}

const getActionLogDetails = async (queryString) => {
  var response = await axios.get(`ActionLog/actionLogDetails?${queryString}`).catch(console.log)
  return response?.data
}

const getActionLogSummaries = async (queryString) => {
  var response = await axios.get(`ActionLog/actionLogSummaries?${queryString}`).catch(console.log)
  return response?.data
}

export const ActionLogService = {
  getSearchCriteria,
  getActionLogDetails,
  getActionLogSummaries
}

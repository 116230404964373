import React, { useState, useEffect } from 'react'
import { Form, Input, Checkbox, Button, Alert } from 'antd'

import Account from '@2fd/ant-design-icons/lib/Account'
import Lock from '@2fd/ant-design-icons/lib/Lock'
import logo from '../logo.svg'
import './LoginPage.less'

import { AuthService } from '../services'

export const LoginPage = (props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const onFinish = async (values) => {
    setLoading(true)
    const response = await AuthService.login(values.username, values.password)
    setLoading(false)
    if (!response?.result?.succeeded) setError(response?.result?.message ?? 'Invalid user name or password')
    else props.history.push('/')
  }

  useEffect(() => {
    if (AuthService.currentUserValue) {
      props.history.push('/')
    }
  }, [props.history])

  return (
    <div className="login-form-container">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={onFinish} onFieldsChange={() => setError(false)}>
        <Form.Item name="username" rules={[{ required: true, message: 'Please input your User Name!' }]}>
          <Input prefix={<Account className="site-form-item-icon" />} placeholder="User Name" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
          <Input.Password prefix={<Lock className="site-form-item-icon" />} placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        </Form.Item>
        {!!error && (
          <Form.Item>
            <Alert message={error} type="error" showIcon style={{ width: '300px' }} />
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Form, Input, Tag, Switch, Radio, Space, Button, Popconfirm, message } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { QuestionCircleOutlined } from '@ant-design/icons'
import ContentCopy from '@2fd/ant-design-icons/lib/ContentCopy'
import Key from '@2fd/ant-design-icons/lib/Key'
import './ApiKeyEditorPage.less'

import { showSuccessMessage, showErrorMessage } from '../../helpers'
import { formItemLayout } from '../../FormLayout'
import { ApiKeyService } from '../../services'

export const ApiKeyEditorPage = (props) => {
  const [editorForm] = Form.useForm()
  const [isNew, setIsNew] = useState(true)
  const [enabledIpRestriction, setEnabledIpRestriction] = useState(false)
  const [roles, setRoles] = useState(['Admin', 'Developer', 'Subscriber'])
  const [token, setToken] = useState('')
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)

  let { id } = useParams()

  useEffect(() => {
    const getRoles = async () => {
      const response = await ApiKeyService.getRoles()
      if (response.isError) showErrorMessage(response.message)
      setRoles(response.result)
    }
    const getApiKey = async (apiKeyId) => {
      let data = { isActive: true, enabledIpRestriction: false, whitelistIps: [], roles: ['Subscriber'] }
      if (apiKeyId) {
        setIsNew(false)
        const response = await ApiKeyService.get(apiKeyId)
        if (response.isError) showErrorMessage(response.message)
        data = response?.result
      }
      setToken(data?.token)
      setEnabledIpRestriction(data.enabledIpRestriction ?? false)
      editorForm.setFieldsValue(data)
    }

    getRoles()
    getApiKey(id)
  }, [id, editorForm])

  const addNewIpWhiteList = (add) => {
    const errors = editorForm.getFieldError(['whitelistIps', 'ip'])
    const ip = editorForm.getFieldValue(['whitelistIps', 'ip'])
    if (errors.length || !ip) return
    if (editorForm.getFieldValue('whitelistIps')?.indexOf(ip) < 0) {
      add(ip)
    }
    editorForm.resetFields([['whitelistIps', 'ip']], '')
  }

  const handleNewIpInputKeyDown = (e, add) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      addNewIpWhiteList(add)
    }
  }

  const handleFormSubmit = async (data) => {
    setSaving(true)
    console.log(data)
    if (id) {
      let result = await ApiKeyService.update(data)
      if (result.isError) showErrorMessage(`Failed to update API Token. ${result.data.message}`)
      else showSuccessMessage('API Token successfully updated.')
    } else {
      let result = await ApiKeyService.create(data)
      if (result.isError) showErrorMessage(`Failed to create API Token. ${result.data.message}`)
      else showSuccessMessage('API Token successfully created.')
    }
    setSaving(false)
    props.history.push('/apiKeys')
  }

  const handleDelete = async () => {
    setDeleting(true)
    let result = await ApiKeyService.deleteToken(id)
    if (result.isError) showErrorMessage(result?.data?.message ?? 'Failed to delete API Token.')
    else showSuccessMessage('API Token successfully deleted.')
    setDeleting(false)
    props.history.push('/apiKeys')
  }

  return (
    <>
      <Form {...formItemLayout} form={editorForm} onFinish={handleFormSubmit} autoComplete="off">
        {!isNew && (
          <Form.Item name="id" hidden={true}>
            <Input />
          </Form.Item>
        )}
        <Form.Item label="Name" name="userName" rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input />
        </Form.Item>
        {!isNew && (
          <Form.Item label="API Token" name="token" style={{ marginBottom: 0 }}>
            <Form.Item style={{ display: 'inline-block', width: 'calc(100% - 48px)', maxWidth: '400px' }}>
              <Tag className="api-token" color="success" icon={<Key />}>
                {token}
              </Tag>
            </Form.Item>
            <Form.Item style={{ display: 'inline-block', margin: '0 8px', width: '24px' }}>
              <CopyToClipboard text={token} onCopy={() => message.info('Token copied to cliboard')}>
                <Button type="link" size="small" icon={<ContentCopy />} />
              </CopyToClipboard>
            </Form.Item>
          </Form.Item>
        )}
        <Form.Item label="Active" name="isActive" valuePropName="checked" initialValue={false}>
          <Switch />
        </Form.Item>
        <Form.Item label="IP Restriction">
          <Form.Item name="enabledIpRestriction" valuePropName="checked" initialValue={false} style={{ marginBottom: '4px' }}>
            <Switch onChange={() => setEnabledIpRestriction(!enabledIpRestriction)} />
          </Form.Item>
          {enabledIpRestriction && (
            <Form.List name="whitelistIps">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <Form.Item name="ip" rules={[{ pattern: '^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$', message: 'Invalid IP Address' }]} style={{ margin: '8px 4px 8px 0' }}>
                    <Space direction="vertical">
                      <Space>
                        <Input name="ip" style={{ width: '142px' }} onKeyDown={(e) => handleNewIpInputKeyDown(e, add)} />
                        <Button onClick={() => addNewIpWhiteList(add)}>Add</Button>
                      </Space>
                    </Space>
                  </Form.Item>
                  <Space direction="vertical">
                    {fields.map((field, index) => (
                      <Form.Item {...field} key={field.key} noStyle>
                        <Tag color="blue" onClose={() => remove(field.name)} closable>
                          <span style={{ padding: '2px 4px', display: 'inline-block', minWidth: '110px' }}>{editorForm.getFieldValue('whitelistIps')[index]}</span>
                          <Input type="hidden" />
                        </Tag>
                      </Form.Item>
                    ))}
                  </Space>
                </>
              )}
            </Form.List>
          )}
        </Form.Item>
        <Form.Item label="Role" name="role" rules={[{ required: true }]}>
          <Radio.Group options={roles} style={{ width: '150px', marginTop: '6px' }} />
        </Form.Item>
        <Form.Item label="Remarks" name="remarks">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="&nbsp;" colon={false}>
          <Space>
            <Button type="primary" htmlType="submit" loading={saving}>
              Save
            </Button>
            <Link to="/apikeys">
              <Button type="default">Cancel</Button>
            </Link>
            {!isNew && (
              <Popconfirm placement="top" title="Are you sure you want to delete?" onConfirm={handleDelete} icon={<QuestionCircleOutlined style={{ color: 'red' }} />} okText="Yes" cancelText="No">
                <Button type="danger" loading={deleting}>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}

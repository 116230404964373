import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { AppLayout } from './AppLayout'
import { UserContext } from './UserContext'

export const PrivateRoute = ({ component: Component, title, ...rest }) => {
  const { user } = useContext(UserContext)

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        return (
          <AppLayout title={title}>
            <Component {...props} />
          </AppLayout>
        )
      }}
    />
  )
}

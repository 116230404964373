import axios from 'axios'

const getAll = async () => {
  const response = await axios.get('users').catch(console.log)
  return response?.data
}

const get = async (id) => {
  const response = await axios.get(`users/${id}`).catch(console.log)
  return response?.data
}

const getUserRoles = async () => {
  const response = await axios.get('users/UserRoles').catch(console.log)
  return response?.data
}

const create = async (data) => {
  try {
    const response = await axios.post('users', data)
    return response?.data
  } catch (error) {
    return error.response?.data
  }
}

const update = async (data) => {
  try {
    const response = await axios.put('users', data)
    return response?.data
  } catch (error) {
    return error.response?.data
  }
}

const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`users/${id}`)
    return response?.data
  } catch (error) {
    return error.response?.data
  }
}

const resetPassword = async (data) => {
  return axios.post('users/resetPassword', { id: data.id, password: data.password })
}

export const UserService = {
  getAll,
  get,
  getUserRoles,
  create,
  update,
  deleteUser,
  resetPassword
}

import { Descriptions, Col, Row, Typography  } from 'antd'

const { Text } = Typography

const basicOddsStructure = (homeOdds, awayOdds) => {
    return (
      <>
            <Row><Col className="gutter-row" span={18}><Text style={{ color: "blue" }}>{homeOdds}</Text></Col></Row>
            <Row><Col className="gutter-row" span={18}>{awayOdds}</Col></Row>
      </>
    )
}

const overOuOddsStructure = (homeOuOdds, awayOuOdds, over) => {
    return (
        <>
            {homeOuOdds === undefined && awayOuOdds === undefined ?
                <Descriptions.Item>
                    <Row gutter={24}>
                        <Col className="gutter-row" span={12}>-</Col>
                        <Col className="gutter-row" span={12}><Text style={{ color: "blue" }}>-</Text></Col>
                    </Row >
                    <Row gutter={24}>
                        <Col className="gutter-row" span={12}></Col>
                        <Col className="gutter-row" span={12}>-</Col>
                    </Row>
                </Descriptions.Item>
                :
                <Descriptions.Item>
                    <Row gutter={24}>
                        {homeOuOdds.map((odds, index) =>
                            <>
                                <Col className="gutter-row" span={12}>{index === 0 ? over : ''}</Col>
                                <Col className="gutter-row" span={12}><Text style={{ color: "blue" }}>{odds.handicap ?? '-'}</Text></Col>
                            </>
                        )}
                    </Row>
                    <Row gutter={24}>
                        {awayOuOdds.map((odds, index) =>
                            <>
                                <Col className="gutter-row" span={12}>{index === 0 && Array.isArray(homeOuOdds) && homeOuOdds.length === 0 ? over : ''}</Col>
                                <Col className="gutter-row" span={12}>{odds.handicap ?? '-'}</Col>
                            </>
                        )}
                    </Row>
                </Descriptions.Item>
            }
        </>
    )
}

const groupOverOuOddsStructure = (groupOver, ouOddsArry, showLabel) => {
    return (
        <>
            <Descriptions.Item label={showLabel ? 'Group Over' : null}>
                {<Text span={18}>{groupOver ?? '-'}</Text>}
            </Descriptions.Item>
            <Descriptions.Item label={showLabel ? 'Over/Handicap' : null}>
                <Descriptions.Item>
                    {ouOddsArry === undefined ? overOuOddsStructure(undefined, undefined, undefined) : (Array.isArray(ouOddsArry) && ouOddsArry.length > 0) ? ouOddsArry.map((x) => overOuOddsStructure(x?.homeExchangeOverUnder, x?.awayExchangeOverUnder, x?.xthOver)) : overOuOddsStructure(ouOddsArry?.homeExchangeOverUnder, ouOddsArry?.awayExchangeOverUnder, ouOddsArry?.xthOver)}
                </Descriptions.Item>
            </Descriptions.Item>
        </>
    )
}

const nextOverOuOddsStructure = (homeOuOdds, awayOuOdds, over) => {
    return (
        <Descriptions layout='vertical' column={2} size="small" bordered>
            <Descriptions.Item label="Over">
                {over ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Handicap">
                {homeOuOdds === undefined ?
                    <Row><Col className="gutter-row" span={18}><Text style={{ color: "blue" }}>-</Text></Col></Row> :
                    homeOuOdds.map((odds, index) =>
                        <Row>
                            <Col className="gutter-row" span={18}><Text style={{ color: "blue" }}>{odds.handicap}</Text></Col>
                        </Row>)
                }
                {awayOuOdds === undefined?
                    <Row><Col className="gutter-row" span={18}>-</Col></Row> :
                    awayOuOdds.map((odds, index) =>
                        <Row>
                            <Col className="gutter-row" span={18}>{odds.handicap}</Col>
                        </Row>)
                }
            </Descriptions.Item>
            {/*<Descriptions.Item label="Yes">*/}
            {/*</Descriptions.Item>*/}
            {/*<Descriptions.Item label="No">*/}
            {/*</Descriptions.Item>*/}
        </Descriptions>
    )
}

export const ExchangeOddsInfoCell = (props) => {
    var odds = props.value?.odds ?? []
    var matchInfo = props.value?.matchInfo ?? []
    var xth = matchInfo?.over !== null ? "(Over " + (matchInfo?.over < 20 ? matchInfo?.over + 1 : matchInfo?.over) + ")" : "";
    return (
        <>
            <Descriptions layout='vertical' column={4} bordered>
                <Descriptions.Item label="WhoWinTheToss" style={{width: "10%"}}>
                    {basicOddsStructure(odds?.homeGe?.toFixed(4) ?? '-', odds?.awayGe?.toFixed(4) ?? '-')}
                </Descriptions.Item>
                <Descriptions.Item label="MoneyLine" style={{width: "10%"}}>
                    {basicOddsStructure(
                        odds?.moneyLineOdds?.homeOdds !== null ? typeof odds?.moneyLineOdds?.homeOdds !== 'string' ? !isNaN(parseFloat(odds?.moneyLineOdds?.homeOdds)) ? odds?.moneyLineOdds?.homeOdds?.toFixed(4) : odds?.moneyLineOdds?.homeOdds : odds?.moneyLineOdds?.homeOdds : '-',
                        odds?.moneyLineOdds?.awayOdds !== null ? typeof odds?.moneyLineOdds?.awayOdds !== 'string' ? !isNaN(parseFloat(odds?.moneyLineOdds?.awayOdds)) ? odds?.moneyLineOdds?.awayOdds?.toFixed(4) : odds?.moneyLineOdds?.awayOdds : odds?.moneyLineOdds?.awayOdds : '-') }
                </Descriptions.Item>
                <Descriptions.Item label="Group Over OU" style={{ width: "50%" }}>
                    <Descriptions layout='vertical' column={2} size="small" bordered>
                        {groupOverOuOddsStructure('X + 1', odds?.matchXOnlyOuOdds ?? odds?.xOnlyOuOdds, true)}
                        {groupOverOuOddsStructure('Fixed X', odds?.gxOuOdds, false)}
                        {groupOverOuOddsStructure(matchInfo?.gameType === 'Cricket19_T20'? '20': '5', odds?.totalRunOuOdds, false)}
                    </Descriptions>
                </Descriptions.Item>
                <Descriptions.Item label="Next Over OU" style={{width: "30%"}}>
                    {nextOverOuOddsStructure(odds?.nextGxOuOdds?.homeExchangeOverUnder, odds?.nextGxOuOdds?.awayExchangeOverUnder, odds?.nextGxOuOdds?.xthOver)}
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Form, Input, Switch, Checkbox, Space, Button, Tag, notification, Popconfirm } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { formItemLayout } from '../../FormLayout'
import { UserService } from '../../services'

export const UserEditorPage = (props) => {
  const [userRoles, setUserRoles] = useState([])
  const [isNewUser, setIsNewUser] = useState(true)
  const [enabledIpRestriction, setEnabledIpRestriction] = useState(false)
  const [userForm] = Form.useForm()
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)

  let { id } = useParams()

  useEffect(() => {
    const getUserRoles = async () => {
      const response = await UserService.getUserRoles()
      if (response.isError) showErrorMessage(response.message)
      setUserRoles(response.result)
    }
    const getUser = async (userId) => {
      let data = { isActive: true, enabledIpRestriction: false, ipWhiteLists: [], roles: ['User'] }
      if (userId) {
        setIsNewUser(false)
        const response = await UserService.get(userId)
        if (response.isError) showErrorMessage(response.message)
        data = response?.result
      }
      setEnabledIpRestriction(data.enabledIpRestriction ?? false)
      userForm.setFieldsValue(data)
    }

    getUserRoles()
    getUser(id)
  }, [id, userForm])

  const showSuccessMessage = (message) => notification['success']({ message: message, placement: 'bottomRight' })
  const showErrorMessage = (message) => notification['error']({ message: message, placement: 'bottomRight' })

  const addNewIpWhiteList = (add) => {
    const errors = userForm.getFieldError(['ipWhiteLists', 'ip'])
    const ip = userForm.getFieldValue(['ipWhiteLists', 'ip'])
    if (errors.length || !ip) return
    if (userForm.getFieldValue('ipWhiteLists')?.indexOf(ip) < 0) {
      add(ip)
    }
    userForm.resetFields([['ipWhiteLists', 'ip']], '')
  }

  const handleNewIpInputKeyDown = (e, add) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      addNewIpWhiteList(add)
    }
  }

  const handleFormSubmit = async (user) => {
    setSaving(true)
    if (id) {
      let result = await UserService.update(user)
      if (result.isError) showErrorMessage(`Failed to update User. ${result.data.message}`)
      else showSuccessMessage('User successfully updated.')
    } else {
      let result = await UserService.create(user)
      if (result.isError) showErrorMessage(`Failed to create User. ${result.data.message}`)
      else showSuccessMessage('User successfully created.')
    }
    setSaving(false)
    props.history.push('/users')
  }

  const handleDelete = async () => {
    setDeleting(true)
    let result = await UserService.deleteUser(id)
    if (result.isError) showErrorMessage(result?.data?.message ?? 'Failed to delete User.')
    else showSuccessMessage('User successfully deleted.')
    setDeleting(false)
    props.history.push('/users')
  }

  return (
    <Form {...formItemLayout} form={userForm} onFinish={handleFormSubmit} autoComplete="off">
      {!isNewUser && (
        <Form.Item name="id" hidden={true}>
          <Input />
        </Form.Item>
      )}
      <Form.Item label="Username" name="userName" rules={[{ required: true }]}>
        <Input disabled={!isNewUser} />
      </Form.Item>
      <Form.Item label="Full Name" name="fullName" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Email" name="email" rules={[{ type: 'email' }]}>
        <Input />
      </Form.Item>
      {isNewUser ? (
        <>
          <Form.Item label="Password" name="password" rules={[{ required: true }]}>
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('The Password and Confirm Password do not match!'))
                }
              })
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
        </>
      ) : null}
      <Form.Item label="Active" name="isActive" valuePropName="checked" initialValue={false}>
        <Switch />
      </Form.Item>
      <Form.Item label="IP Restriction">
        <Form.Item name="enabledIpRestriction" valuePropName="checked" initialValue={false} style={{ marginBottom: '4px' }}>
          <Switch onChange={() => setEnabledIpRestriction(!enabledIpRestriction)} />
        </Form.Item>
        {enabledIpRestriction && (
          <Form.List name="ipWhiteLists">
            {(fields, { add, remove }, { errors }) => (
              <>
                <Form.Item name="ip" rules={[{ pattern: '^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$', message: 'Invalid IP Address' }]} style={{ margin: '8px 4px 8px 0' }}>
                  <Space direction="vertical">
                    <Space>
                      <Input name="ip" style={{ width: '142px' }} onKeyDown={(e) => handleNewIpInputKeyDown(e, add)} />
                      <Button onClick={() => addNewIpWhiteList(add)}>Add</Button>
                    </Space>
                  </Space>
                </Form.Item>
                <Space direction="vertical">
                  {fields.map((field, index) => (
                    <Form.Item {...field} key={field.key} noStyle>
                      <Tag color="blue" onClose={() => remove(field.name)} closable>
                        <span style={{ padding: '2px 4px', display: 'inline-block', minWidth: '110px' }}>{userForm.getFieldValue('ipWhiteLists')[index]}</span>
                        <Input type="hidden" />
                      </Tag>
                    </Form.Item>
                  ))}
                </Space>
              </>
            )}
          </Form.List>
        )}
      </Form.Item>
      <Form.Item label="Roles" name="roles" rules={[{ required: true }]}>
        <Checkbox.Group options={userRoles} style={{ width: '150px', marginTop: '6px' }} />
      </Form.Item>
      <Form.Item label="Remarks" name="remarks">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="&nbsp;" colon={false}>
        <Space>
          <Button type="primary" htmlType="submit" loading={saving}>
            Save
          </Button>
          <Link to="/users">
            <Button type="default">Cancel</Button>
          </Link>
          {!isNewUser && (
            <Popconfirm placement="top" title="Are you sure you want to delete?" onConfirm={handleDelete} icon={<QuestionCircleOutlined style={{ color: 'red' }} />} okText="Yes" cancelText="No">
              <Button type="danger" loading={deleting}>
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      </Form.Item>
    </Form>
  )
}

import axios from 'axios'

const broadcastMessage = async (data) => {
    try {
        const response = await axios.get(`EsMessenger/broadcastMessage?message=${data}`)
        return response?.data
      } catch (error) {
        return error.response?.data
      }
}

const listLogFileDirectory = async (data) => {
  try {
      const response = await axios.get(`EsMessenger/listLogFileDirectory?pcId=${data}`)
      return response?.data?.result
    } catch (error) {
      return error.response?.data
    }
}

const requestEsLogFile = async (pcId, logPath) => {
  try {
      const response = await axios.get(`EsMessenger/requestEsLogFile?pcId=${pcId}&logPath=${logPath}`)
      return response?.data
    } catch (error) {
      return error.response?.data
    }
}

export const MessengerService = {
    broadcastMessage,
    listLogFileDirectory,
    requestEsLogFile
}

import axios from 'axios'
import { AuthService } from './services'

axios.defaults.baseURL = 'api/'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (401 === error.response.status) {
      AuthService.logout()
    }
    return Promise.reject(error)
  }
)

AuthService.currentUser.subscribe((x) => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + (x?.accessToken || '')
})

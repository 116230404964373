export const UserInfo = function (jwtTokenResult) {
  if (!jwtTokenResult?.accessToken) return null

  const token = jwtTokenResult.accessToken
  const data = JSON.parse(atob(token.split('.')[1]))

  this.accessToken = jwtTokenResult?.accessToken
  this.id = data['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
  this.name = data['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
  this.role = data['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
  this.lang = data['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality']
  this.displayName = data['DisplayName'] || this.name
  this.exp = data.exp
  this.refreshToken = jwtTokenResult.refreshToken
  const permissionList = typeof data.permissions === 'string' ? JSON.parse(data.permissions) : data.permissions?.data

  this.permissions = {
    data: permissionList
  }

  permissionList?.forEach((x) => {
    const propertyName = `can${x.permissionName.replace(/[\s|,]/gi, '')}`
    this.permissions[propertyName] = true
  })
}

import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { PageHeader, Table, Popover, Button, Layout, Tag, Typography } from 'antd'
import { PlusOutlined, InfoCircleTwoTone, StopFilled, LoadingOutlined } from '@ant-design/icons'

import { EsmUserService, EsmUserPermissionService } from '../../services'

export const EsmUserListPage = () => {
    const { Title } = Typography
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [isAccessKeyValid, setIsAccessKeyValid] = useState(null)
    const { Content } = Layout
    const [emUser, setEmUser] = useState({})

    var search = useLocation().search
    var userId = new URLSearchParams(search).get("userId")
    var pcId = new URLSearchParams(search).get("pcId")
    var accessKey = new URLSearchParams(search).get("accessKey")

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            sorter: (a, b) => a.username?.localeCompare(b.username),
            render: (text, row) => (
                EsmUserPermissionService.canEdit(emUser.role) ? <Link to={{ pathname: `/esmUsers/${row.id}`, state: { userId: userId } }}>{text}</Link> : text
            )
        },
        {
            title: 'Role',
            key: 'role',
            dataIndex: 'role',
            render: (role) => (
                <>
                    <Tag color={getRoleColor(role)} key={role}>
                      {role}
                    </Tag>
                </>
            )
        },
        {
            title: 'Remarks',
            key: 'remarks',
            dataIndex: 'remark',
            render: (text) => {
                return text ? (
                    <Popover placement="topRight" arrowPointAtCenter content={text}>
                        <InfoCircleTwoTone />
                    </Popover>
                ) : (
                    <></>
                )
            }
        },
        {
            title: '',
            key: 'action',
            render: (_, row) => (
                <Link to={{pathname: `/esmUsers/changepassword/${row.id}`, state: {userId: userId, username: row.username}}}>
                    <Button type="link">Change Password</Button>
                </Link>
            )
        }
    ]

    async function IsEsAccessKeyValid() {
        var response = await EsmUserService.isEsAccessKeyValid(pcId, accessKey)
        if (response) {
          return response.result.result
        }
        return false
      }

    useEffect(() => {
        const getUsers = async () => {
            var isValid = false
            var showAdminAccounts = false
            let users = []
            if (localStorage.user != null && localStorage.user !== 'null') {
                isValid = true
                showAdminAccounts = true
                userId = 1
            }
            else if (pcId !== null && accessKey !== null){
                isValid = await IsEsAccessKeyValid()
            }
            if (userId !== null) {
                isValid = true
                const response = await EsmUserService.get(userId)
                users.push(response?.result)
                setEmUser(response?.result)
            }
    
            setIsAccessKeyValid(isValid)

            if (isValid) {
                if (EsmUserPermissionService.canViewOwn(emUser.role)) {
                    setData(users ?? [])
                }
                else {
                    const response = await EsmUserService.getAllByLoginRole(userId, showAdminAccounts)
                    setData(response?.result ?? [])
                }
            }
            
            setLoading(false)
          }
          getUsers()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    function getRoleColor(role){
        if(role == 'Admin')
            return 'geekblue'
        else if(role == 'Supervisor')
            return 'blue'
        else if(role == 'TeamLeader')
            return 'cyan'
        else
            return 'green'
    }

    return (userId !== undefined || userId !== null || localStorage.user !== null)
    ?
    <>
        {isAccessKeyValid != null && isAccessKeyValid ?
        <>
            <PageHeader
                title="ESM User"
                extra={[
                    EsmUserPermissionService.canCreate(emUser.role) ? <Link key="new-esm-user-link" to={{pathname: '/esmUsers/new', state: {userId: userId}}}>
                        <Button key="1" icon={<PlusOutlined />} type="primary">
                            New
                        </Button>
                    </Link> : <></>
                ]}
            />
            <Content style={{
                margin: '20px',
            }}>
                <Table rowKey="id" columns={columns} dataSource={data} size="small" loading={loading} bordered />
            </Content>
        </>
        : <Title level={4}>&nbsp;&nbsp;
            {isAccessKeyValid == null
            ? <><LoadingOutlined /> {'Loading, Please wait...'}</>
            : <><StopFilled /> {`Unauthorized Access 2. UserId: ${userId} LocalStorageUser: ${localStorage?.user} IsAccessKeyValid: ${isAccessKeyValid}`}</> }
        </Title>}
    </>
    :
    <>
        <Title level={4}>&nbsp;&nbsp;
            <StopFilled /> {`Unauthorized Access 1. UserId: ${userId} LocalStorageUser: ${localStorage?.user} IsAccessKeyValid: ${isAccessKeyValid}`}
        </Title>
    </> 
}

import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Typography, Collapse, Space, Card, Tag  } from 'antd'
import moment from 'moment'
import { EmVersionService } from '../../services'
import { StopFilled, LoadingOutlined } from '@ant-design/icons'

export const EsmVersionHistoryPage = (props) => {
    const { Title, Text } = Typography
    const { Panel } = Collapse;
    const [versions, setVersions] = useState([])
    const [isAccessKeyValid, setAccessKeyValid] = useState(null)

    var search = useLocation().search
    var pcId = new URLSearchParams(search).get("pcId")
    var accessKey = new URLSearchParams(search).get("accessKey")

    async function GetPackageHistories(isActive, count, sortDesc) {
      var response = await EmVersionService.getEmVersions(isActive, count, sortDesc)
      if (response) {
        return response.result
      }
      return null
    }

    async function IsEsAccessKeyValid() {
      var response = await EmVersionService.isEsAccessKeyValid(pcId, accessKey)
      if (response) {
        return response.result.result
      }
      return false
    }

    useEffect(() => {
      const getPackageHistories = async () => {
        var isValid = false
        if (localStorage.user != null && localStorage.user !== 'null'){
          isValid = true
          setAccessKeyValid(isValid)
        }
        else{
          isValid = await IsEsAccessKeyValid()
          setAccessKeyValid(isValid)
        }

        if (isValid){
          var packageVersions = await GetPackageHistories(true, 5, true)
          setVersions(packageVersions)
        }
      }
      getPackageHistories()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <>
    {isAccessKeyValid != null && isAccessKeyValid ?
    <>
      <Title level={4}>&nbsp;&nbsp;&nbsp;Latest ESM Package Info</Title>

      <Collapse accordion>
        {
          versions.sort((a, b) => b.packageVersion.localeCompare(a.packageVersion))
          .map((info, index) => (
            <Panel header={<Text strong>{info.packageVersion} [{moment(info.createdDate).format('yyyy-MM-DD')}]</Text>} key={info.version}>

                <Space
                  direction="vertical"
                  size="middle"
                  style={{
                    display: 'flex',
                  }}
                >
                  <Card size="small" type="inner">
                    Download Link: <a href={info.downloadUrl} target="_blank">{info.downloadUrl}</a>
                  </Card>
                <Card title="Change Log" size="small">
                  <Text style={{ whiteSpace: "pre-line" }}>
                    {info.changelog}
                  </Text>
                </Card>
                <Card title="Affected System" size="small">
                  {info.affectedSystemList != null ? info.affectedSystemList.map((number) => <Tag color="blue">{number}</Tag>) : ''}
                  {/* {info.affectedSystem} */}
                </Card>
                <Card title="Note" size="small">
                  <p>{info.note}</p>
                </Card>
              </Space>
          </Panel>
          ))
        }
      </Collapse>
    </>
    : <Title level={4}>&nbsp;&nbsp;
        {isAccessKeyValid == null
        ? <><LoadingOutlined /> {'Loading, Please wait...'}</>
        : <><StopFilled /> {'Unauthorized Access'}</> }
      </Title>}

    </>
}

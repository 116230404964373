import React, { useState, useEffect } from 'react'
import { EsmReportSearchForm, EsmReportTable } from '.'
import { ActionLogService } from '../../services'
import moment from 'moment'

export const EsmDetailReportPage = (props) => {
    const [actionLogs, setActionLogs] = useState(null)
    const [searchCriteriaOptions, setSearchCriteriaOptions] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedSearchCriteria, setSelectedSearchCriteria] = useState(
      {
        pcid: '',
        appVer: '',
        message: '',
        event: '',
        reportStart: moment(),
        reportEnd: moment()
      })

    function objectToQueryString(obj) {
        var str = []
        for (var p in obj) {
          if (obj[p]) {
            if (obj.hasOwnProperty(p)) {
              if (Array.isArray(obj[p])) {
                for (var arr in obj[p]) {
                  str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p][arr]))
                }
              } else {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
              }
            }
          }
        }

        return str.join('&')
      }

    async function onSearch(searchCriteria) {
        setActionLogs(null)
        var actionLogs = await GetActionLogs(searchCriteria)
        setActionLogs(actionLogs)
    }

    async function GetActionLogs(searchCriteria) {
        var criteria = {
          FromDate: searchCriteria.reportStart ? moment(searchCriteria.reportStart).format('yyyy-MM-DD') : '',
          ToDate: searchCriteria.reportEnd ? moment(searchCriteria.reportEnd).format('yyyy-MM-DD') : '',
          PcId: searchCriteria.pcid,
          Mode: searchCriteria.mode,
          Game: searchCriteria.game,
          Action: searchCriteria.action,
          AppVersion: searchCriteria.appVer,
          Message: searchCriteria.message,
          Event: searchCriteria.event
        }

        var queryString = objectToQueryString(criteria)
        setIsLoading(true)
        var response = await ActionLogService.getActionLogDetails(queryString)
        setIsLoading(false)
        if (response) {
          return response.result
        }
        return null
      }

    async function GetSearchCriteria() {
      setIsLoading(true)
      var response = await ActionLogService.getSearchCriteria()
      setIsLoading(false)
      if (response) {
        return response.result
      }
      return null
    }

    useEffect(() => {
      const getDropDownSearchCriteria = async () => {
        var searchCriteriaList = await GetSearchCriteria()
        setSearchCriteriaOptions(searchCriteriaList)
      }
      getDropDownSearchCriteria()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <EsmReportSearchForm onSearch={onSearch} searchCriteriaOptions={searchCriteriaOptions} selectedSearchCriteria={selectedSearchCriteria} setSelectedSearchCriteria={setSelectedSearchCriteria}/>
            <EsmReportTable dataSource={actionLogs} isLoading={isLoading} setSelectedSearchCriteria={setSelectedSearchCriteria}/>
        </>
    )
}

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { PageHeader, Table, Tag, Button } from 'antd'
import { PlusOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import moment from 'moment'

import { ApiKeyService } from '../../services'

export const ApiKeyListPage = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'userName',
      key: 'userName',
      sorter: (a, b) => a.userName?.localCompare(b.userName),
      render: (text, row) => <Link to={`/apikeys/${row.id}`}>{text}</Link>
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      sorter: (a, b) => a.role?.localCompare(b.role),
      render: (role) => (
        <>
          <Tag color={role === 'Administrator' ? 'cyan' : 'green'} key={role}>
            {role.toUpperCase()}
          </Tag>
        </>
      )
    },
    {
      title: 'Is Active',
      key: 'isAvtive',
      dataIndex: 'isActive',
      sorter: (a, b) => a.isActive - b.isActive,
      render: (isActive) => (isActive ? <CheckCircleFilled style={{ color: '#52c41a', fontSize: '18px' }} /> : <CloseCircleFilled style={{ color: '#8c8c8c', fontSize: '18px' }} />)
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
      render: (value) => {
        const date = moment(value)
        return date.isValid() ? date.format('YYYY-MM-DD hh:mm A') : ''
      }
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedDate',
      key: 'updatedDate',
      sorter: (a, b) => new Date(a.updatedDate) - new Date(b.updatedDate),
      render: (value) => {
        const date = moment(value)
        return date.isValid() ? date.format('YYYY-MM-DD hh:mm A') : ''
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      sorter: (a, b) => a.remarks?.localeCompare(b.remarks)
    }
  ]

  useEffect(() => {
    const getApiKeys = async () => {
      const response = await ApiKeyService.getAll()
      setData(response?.result ?? [])
      setLoading(false)
    }
    getApiKeys()
  }, [])

  return (
    <>
      <PageHeader
        extra={[
          <Link key="new-apikey-link" to="/apikeys/new">
            <Button icon={<PlusOutlined />} type="primary">
              New
            </Button>
          </Link>
        ]}
      />
      <Table rowKey="id" columns={columns} dataSource={data} size="small" loading={loading} bordered />
    </>
  )
}

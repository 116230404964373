import React, { useEffect, useState } from 'react'
import { Button, Divider, List, Input, Row, Col, Radio, Typography, message } from 'antd'
import { MessengerService, InfoService } from '../../services'
import { SearchOutlined, FileOutlined, SyncOutlined } from '@ant-design/icons'

export const SendMessagePage = (props) => {
    const [logDirectories, setLogDirectories] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedLogDirectory, setSelectedLogDirectory] = useState('')
    const { Title } = Typography
    const [esportClientInfo, setEsportClientInfo] = useState([])
    const [selectedPcId, setSelectedPcId] = useState('')

    // async function sendAnnouncement() {
    //     await MessengerService.broadcastMessage('Hi Everyone!')
    // }

    async function listLogFileDirectory() {
        setLogDirectories([])
        setLoading(true)
        setSelectedLogDirectory('')

        var pcLogDirectories = await MessengerService.listLogFileDirectory(selectedPcId)
        if(pcLogDirectories !== null && pcLogDirectories.logFileNames.length > 0){
            var dirArray = [];
            var i;
            for (i = 0; i < pcLogDirectories.logFileNames.length; i++) {
                dirArray[i] = pcLogDirectories.logFilePath.concat('\\', pcLogDirectories.logFileNames[i]);
            }
            setLogDirectories(dirArray ?? [])
        }
        
        setLoading(false)

        if(pcLogDirectories?.result === null ){
            message.error(pcLogDirectories?.message)
        }
    }

    async function requestLog() {
        setLoading(true)
        var loadingMsg = message.loading('Uploading log file from gaming pc...', 0)
        await MessengerService.requestEsLogFile(selectedPcId, selectedLogDirectory)
        setLoading(false)
        setTimeout(loadingMsg, 0)

        message.info('Please check SimCity server for the requested log file.', 5)
    }

    function onClick(x) {
        setSelectedLogDirectory(x)
    }

    const reloadEsportClientInfo = async () => {
        setLoading(true)
        const response = await InfoService.getEsportClientInfo()
        setEsportClientInfo(response?.result?.filter(info => info.pcId !== null) ?? [])
        setLoading(false)
    }

    const onChange = (e) => {
        setSelectedPcId(e.target.value)
    }

    useEffect(() => {
        const getEsportClientInfo = async () => {
            const response = await InfoService.getEsportClientInfo()
            setEsportClientInfo(response?.result?.filter(info => info.pcId !== null) ?? [])
        }
        getEsportClientInfo()
    }, [])

    return (
        <>
            {/* <Button type="primary" onClick={async () => {await sendAnnouncement()}}>Say Hi to everyone</Button> */}
            <Button className="float-right" type="primary" onClick={reloadEsportClientInfo} icon={<SyncOutlined />} loading={loading}>
                Refresh
            </Button>
            <Title level={4}>Available Gaming PCs</Title>
            {esportClientInfo.length > 0 ?
            <>
                <Row >
                    <Radio.Group size="large"
                        onChange={onChange}>
                        {esportClientInfo
                            .sort((a, b) => a.pcId.localeCompare(b.pcId))
                            .map((info) => (
                                <Radio.Button key={info.pcId} value={info.pcId}>{info.pcId}</Radio.Button>
                            ))}
                    </Radio.Group>
                </Row>

                <br />
                <Button type="primary" icon={<SearchOutlined />} loading={loading} disabled={selectedPcId === ''} onClick={async () => {await listLogFileDirectory()}}>List LogFile Directory</Button>
            </>
            : <Title level={5}>No Gaming PC detected</Title>}

            {logDirectories.length > 0 ?
            <>
                <Divider orientation="left"></Divider>
                <List
                    size="small"
                    header={<div>Log File Directories</div>}
                    bordered
                    loading={loading}
                    dataSource={logDirectories}
                    renderItem={item => <List.Item><Button type="link" onClick={() => onClick(item)}>{item}</Button></List.Item>}
                />

                <br />
                <Row>
                    <Col flex={6}><Input placeholder="Log Directory" value={selectedLogDirectory} disabled/></Col>
                    <Col flex={2}><Button type="primary" icon={<FileOutlined />} loading={loading} disabled={selectedLogDirectory === ''} onClick={async () => {await requestLog()}}>Request Log</Button></Col>
                </Row>
            </>
            : ''}
        </>
    )
}

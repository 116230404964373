import axios from 'axios'

const getVirtualSportInfo = async () => {
  var response = await axios.get('Info/VirtualSportInfo').catch(console.log)
  return response?.data
}

const getClientInfo = async (id) => {
  var response = await axios.get('Info/ClientInfo').catch(console.log)
  return response?.data
}

const getEsportClientInfo = async (id) => {
  var response = await axios.get('Info/EsportClientInfo').catch(console.log)
  return response?.data
}

const getEsportGrpcClientInfo = async (id) => {
  var response = await axios.get('Info/EsportGrpcClientInfo').catch(console.log)
  return response?.data
}

export const InfoService = {
  getVirtualSportInfo,
  getClientInfo,
  getEsportClientInfo,
  getEsportGrpcClientInfo
}

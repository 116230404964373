import axios from 'axios'

const getAll = async () => {
  var response = await axios.get('ApiTokens').catch(console.log)
  return response?.data
}

const get = async (id) => {
  var response = await axios.get(`ApiTokens/${id}`).catch(console.log)
  return response?.data
}

const getRoles = async () => {
  var response = await axios.get(`ApiTokens/roles`).catch(console.log)
  return response?.data
}

const create = async (data) => {
  try {
    data.userId = data.userId ?? data.userName
    const response = await axios.post('ApiTokens', data)
    return response?.data
  } catch (error) {
    return error.response?.data
  }
}

const update = async (data) => {
  try {
    const response = await axios.put('ApiTokens', data)
    return response?.data
  } catch (error) {
    return error.response?.data
  }
}

const deleteToken = async (token) => {
  try {
    const response = await axios.delete(`ApiTokens/${token}`)
    return response?.data
  } catch (error) {
    return error.response?.data
  }
}

export const ApiKeyService = {
  getAll,
  get,
  getRoles,
  create,
  update,
  deleteToken
}

import { Tag, Popover, Col, Row, Typography, Timeline, Tooltip } from 'antd'
import { InfoCircleTwoTone, CaretRightFilled } from '@ant-design/icons'
import moment from 'moment'

const { Text } = Typography

const infoContent = (value) => {
    return (
        <>
            <Timeline>
                <Timeline.Item>MatchInfo Created: {value.createdTimestamp ? moment(value.createdTimestamp)?.format('YYYY-MM-DD HH:mm:ss.SSS') : ''}</Timeline.Item>
                <Timeline.Item>Cacher Received: {value.cacherReceivedTimestamp ? moment(value.cacherReceivedTimestamp)?.format('YYYY-MM-DD HH:mm:ss.SSS') : ''}</Timeline.Item>
                <Timeline.Item>OddsEngine Received: {value.oddsEngineReceivedTimestamp ? moment(value.oddsEngineReceivedTimestamp)?.format('YYYY-MM-DD HH:mm:ss.SSS') : ''}</Timeline.Item>
                <Timeline.Item>Odds Calculated: {value.processTimestamp ? moment(value.processTimestamp)?.format('YYYY-MM-DD HH:mm:ss.SSS') : ''}</Timeline.Item>
                <Timeline.Item>Odds Published: {value.publishTimestamp ? moment(value.publishTimestamp)?.format('YYYY-MM-DD HH:mm:ss.SSS') : ''}</Timeline.Item>
            </Timeline>
        </>
    )
}

export const EventMatchInfoCell = (props) => {
    var data = props?.value?.matchInfoOdds?.matchInfo ?? []
    var eventLinkId = props?.value?.eventLinkId
    var eventId = props?.value?.eventId
    var secondaryEventId = data?.fancyEventId
    var isHomeBatting = data?.homeTeamName === data?.currentBattingTeam ? true : false;
    var isAwayBatting = data?.awayTeamName === data?.currentBattingTeam ? true : false;
    return (
        <>
            <Row>
                <Col className="gutter-row" span={20}>
                    <div>
                        <Tooltip title="EventLinkId"><a style={{ marginRight: '8px' }}>{eventLinkId ?? ""}</a></Tooltip>
                        <Tooltip title={secondaryEventId === 0 ? "SportbookEventId" : "BookmakerEventId" }><span style={{ marginRight: '8px' }}>({eventId ?? ""})</span></Tooltip>
                        {secondaryEventId > 0 ? <Tooltip title="FancyEventId"><span style={{ marginRight: '8px' }}>({secondaryEventId})</span></Tooltip> : <></>}
                        <Tag color="cyan">{data?.gameType ?? ""}</Tag>
                        <Popover placement="rightBottom" title="Timeline" content={infoContent(data)}>
                            <InfoCircleTwoTone />
                        </Popover>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="gutter-row" span={20}><Tooltip title="Kickoff Time">{moment(data.kickoffTime)?.format('YYYY-MM-DD HH:mm:ss') ?? ''}</Tooltip></Col>
            </Row>
            <Row>
                <Col className="gutter-row" span={20}>{isHomeBatting ? <CaretRightFilled /> : <></>}<Text style={{ color: "blue" }}>{data?.homeTeamName ?? ""}</Text></Col>
            </Row>
            <Row>
                <Col className="gutter-row" span={20}>{isAwayBatting ? <CaretRightFilled /> : <></>}{data?.awayTeamName ?? ""}</Col>
            </Row>
            <br />
            <Row gutter={18}>
                <Col className="gutter-row" span={6}><Tag color="cyan">{data?.inning ?? ""}</Tag></Col>
                <Col className="gutter-row" span={6}><Tag color="green">Over: {data?.over ?? ""}</Tag></Col>
                <Col className="gutter-row" span={6}><Tag color="green">Delivery: {data?.delivery ?? ""}</Tag></Col>
            </Row>
            <Row gutter={18}>
                <Col className="gutter-row" span={6}><Tag color="orange">Total Runs</Tag></Col>
                <Col className="gutter-row" span={6}><Tag color="lime">1st Inning: {data?.firstInningTotalRuns ?? ""}</Tag></Col>
                <Col className="gutter-row" span={6}><Tag color="lime">2nd Inning: {data?.secondInningTotalRuns ?? ""}</Tag></Col>
            </Row>
            <Row gutter={18}>
                <Col className="gutter-row" span={9}><Tag color="red">TotalBatsmanOut: {data?.totalBatsmanOut ?? ""}</Tag></Col>
            </Row>
            <Row gutter={18}>
                <Col className="gutter-row" span={9}><Tag color="blue">Odds Processing Time(ms): {data?.oddsProcessTimeTakenInMs ?? ""}</Tag></Col>
            </Row>
        </>
    )
}

import axios from 'axios'

const isEsAccessKeyValid = async (pcId, accessKey) => {
    var response = await axios.get(`esmUser/VerifyEsAccessKey?pcId=${pcId}&accessKey=${accessKey}`).catch(console.log)
    return response?.data
  }

const getAll = async () => {
    const response = await axios.get('esmUser/AllUsers').catch(console.log)
    return response?.data
}

const getAllByLoginRole = async (userId, showAdminAccounts = false) => {
    const response = await axios.get(`esmUser/EmWidgetUsersByLoginRole?userId=${userId}&showAdminAccounts=${showAdminAccounts}`).catch(console.log)
    return response?.data
}

const get = async (username) => {
    const response = await axios.get(`esmUser/${username}`).catch(console.log)
    return response?.data
}

const getUserRoles = async () => {
    const response = await axios.get('esmUser/UserRoles').catch(console.log)
    return response?.data
}

const create = async (data) => {
    try {
        const response = await axios.post('esmUser', data)
        return response?.data
    } catch (error) {
        return error.response?.data
    }
}

const updateUser = async (data) => {
    try {
        const response = await axios.put('esmUser/UpdateUser', data)
        return response?.data
    } catch (error) {
        return error.response?.data
    }
}

const updateUserPassword = async (data) => {
    try {
        const response = await axios.put('esmUser/UpdateUserPassword', data)
        return response?.data
    } catch (error) {
        return error.response?.data
    }
}

const updateUserStatus = async (data) => {
    try {
        const response = await axios.put('esmUser/UpdateUserStatus', data)
        return response?.data
    } catch (error) {
        return error.response?.data
    }
}

export const EsmUserService = {
    isEsAccessKeyValid,
    getAll,
    getAllByLoginRole,
    get,
    getUserRoles,
    create,
    updateUser,
    updateUserPassword,
    updateUserStatus
  }

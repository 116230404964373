import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Layout, PageHeader, Avatar, Dropdown, Menu } from 'antd'
import { UserContext } from './UserContext'
import { UserOutlined } from '@ant-design/icons'

import Logout from '@2fd/ant-design-icons/lib/Logout'
import Account from '@2fd/ant-design-icons/lib/Account'
import Key from '@2fd/ant-design-icons/lib/Key'
import './AppLayout.less'

import { AuthService } from '../../services'
import { Sidebar } from './Sidebar'

export const AppLayout = ({ title, userName, children }) => {
  const { user } = useContext(UserContext)

  function logout() {
    AuthService.logout()
  }

  const UserMenu = (
    <Menu className="account-menu">
      <Menu.Item key="1" icon={<Account />}>
        <Link to="/account/profile">Profile</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<Key />}>
        <Link to="/account/changepassword">Change Password</Link>
      </Menu.Item>
      <Menu.Divider key="3" />
      <Menu.Item key="4" icon={<Logout />} onClick={logout}>
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  )

  const UserAvatar = () => (
    <Dropdown key="more" overlay={UserMenu} placement="bottomRight" arrow>
      <div>
        {user?.displayName} <Avatar className="user-avatar" icon={<UserOutlined />} />
      </div>
    </Dropdown>
  )

  return (
    <>
      <Layout className="app-layout">
        <Sidebar />
        <Layout>
          <PageHeader ghost={false} title={title} extra={[<UserAvatar key="user" />]} />
          <Layout.Content className="app-content">{children}</Layout.Content>
        </Layout>
      </Layout>
    </>
  )
}

import axios from 'axios'

const getECricketSportbookOddsEngineMonitoringInfo = async () => {
    var response = await axios.get('OddsEngineMonitoring/eCricketSportbookOddsEngineMonitoringInfo').catch(console.log)
    return response?.data
}

const getECricketExchangeOddsEngineMonitoringInfo = async () => {
    var response = await axios.get('OddsEngineMonitoring/eCricketExchangeOddsEngineMonitoringInfo').catch(console.log)
    return response?.data
}

const getECricketFormulaInning = async () => {
    var response = await axios.get('OddsEngineMonitoring/eCricketFormulaInning').catch(console.log)
    return response?.data
}

export const OddsEngineMonitoringService = {
    getECricketSportbookOddsEngineMonitoringInfo,
    getECricketExchangeOddsEngineMonitoringInfo,
    getECricketFormulaInning
}

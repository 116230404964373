import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConfigProvider, Layout } from 'antd'

import './App.less'
import { PrivateRoute } from './components/core/PrivateRoute'
import { LoginPage } from './pages/LoginPage'
import { ProfilePage } from './pages/account/ProfilePage'
import { ChangePasswordPage } from './pages/account/ChangePasswordPage'

import { DashboardPage } from './pages/DashboardPage'
import { UserListPage, UserEditorPage, ResetPasswordPage } from './pages/users'
import { ApiKeyListPage, ApiKeyEditorPage } from './pages/apikeys'
import { EsmDetailReportPage, EsmSummaryReportPage, EsmHelpPage } from './pages/esm'
import { SendMessagePage } from './pages/messenger'
import { EsmPackageHistoriesPage, EsmPackageEditorPage, EsmVersionHistoryPage } from './pages/esmPackage'
import { BugReportMonitoringPage } from './pages/esmUtilities'
import { OddsEngineSettingEditorPage, OddsEngineSettingListPage, OddsEngineMonitoringPage } from './pages/oddsEngine'
import { EsmUserListPage, EsmUserEditorPage, EsmUserPasswordEditorPage } from './pages/esmUsers'

import { validateMessages } from './ValidateMessages'
import './axios'

export default function App() {
  return (
    <Layout className="app-layout">
      <ConfigProvider form={{ validateMessages }}>
        <Switch>
          <Route exact path="/login" component={LoginPage} />

          <PrivateRoute exact path="/account/profile" component={ProfilePage} title="Profile" />
          <PrivateRoute exact path="/account/changepassword" component={ChangePasswordPage} title="Change password" />

          <PrivateRoute exact path="/users" component={UserListPage} title="Users" />
          <PrivateRoute exact path="/users/new" component={UserEditorPage} title="New User" />
          <PrivateRoute exact path="/users/:id" component={UserEditorPage} title="Edit User" />
          <PrivateRoute exact path="/users/resetpassword/:id" component={ResetPasswordPage} title="Reset Password" />

          <PrivateRoute exact path="/apikeys" component={ApiKeyListPage} title="API Keys" />
          <PrivateRoute exact path="/apikeys/new" component={ApiKeyEditorPage} title="New API Key" />
          <PrivateRoute exact path="/apikeys/:id" component={ApiKeyEditorPage} title="Edit API Key" />

          <PrivateRoute exact path="/esm/detail" component={EsmDetailReportPage} title="Detail Report" />
          <PrivateRoute exact path="/esm/summary" component={EsmSummaryReportPage} title="Summary Report" />
          <Route path="/esm/help" component={EsmHelpPage} title="ESM User Manual" />

          <PrivateRoute exact path="/messenger" component={SendMessagePage} title="ESM Request Log" />

          <PrivateRoute exact path="/esmPackage/package" component={EsmPackageHistoriesPage} title="ESM Package Histories" />
          <PrivateRoute exact path="/esmPackage/package/new" component={EsmPackageEditorPage} title="New ESM Package" />
          <PrivateRoute exact path="/esmPackage/package/:id" component={EsmPackageEditorPage} title="Edit ESM Package" />
          <Route path="/esmPackage/version" component={EsmVersionHistoryPage} title="Latest ESM Package Info" />

          <PrivateRoute exact path="/oddsEngine/setting" component={OddsEngineSettingListPage} title="Odds Engine Settings" />
          <PrivateRoute exact path="/oddsEngine/setting/:id" component={OddsEngineSettingEditorPage} title="Edit Odds Engine Setting" />
          <PrivateRoute exact path="/oddsEngine/monitoring" component={OddsEngineMonitoringPage} title="Odds Engine Monitoring" />

          <Route exact path="/esmUsers/users" component={EsmUserListPage} title="ESM Users" />
          <Route exact path="/esmUsers/new" component={EsmUserEditorPage} title="New ESM User" />
          <Route exact path="/esmUsers/:id" component={EsmUserEditorPage} title="Edit ESM User" />
          <Route exact path="/esmUsers/changepassword/:id" component={EsmUserPasswordEditorPage} title="Change Password" />

          <PrivateRoute exact path="/esmUtilities/bugreportmonitoring" component={BugReportMonitoringPage} title="Bug Report Monitoring" />

          <PrivateRoute exact path="/" component={DashboardPage} title="Dashboard" />
        </Switch>
      </ConfigProvider>
    </Layout>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import { Form, Input, Space, Button, notification } from 'antd'
import { formItemLayout } from '../../FormLayout'

import { AuthService } from '../../services'

export const ChangePasswordPage = (props) => {
  async function handleFormSubmit(data) {
    await AuthService.changePassword(data)
    notification['success']({ message: 'Password successfully changed.', placement: 'bottomRight' })
    props.history.push('/')
  }

  return (
    <>
      <Form {...formItemLayout} onFinish={handleFormSubmit} autoComplete="off">
        <Form.Item label="Password" name="password" rules={[{ required: true }]}>
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The Password and Confirm Password do not match!'))
              }
            })
          ]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Form.Item label="&nbsp;" colon={false}>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Link to="/">
              <Button type="default">Cancel</Button>
            </Link>
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}

import React from 'react'
import { Table, Empty, Button, Descriptions, Popover } from 'antd'
import { SyncOutlined, InfoCircleTwoTone, MoreOutlined } from '@ant-design/icons'

import moment from 'moment'

export const EsmReportTable = ({dataSource, isLoading, setSelectedSearchCriteria}) => {

    function handleFieldClick(field, text) {
        setSelectedSearchCriteria(prevState => ({
            ...prevState,
            [field]: text
        }))
    }

    const infoContent = (info) => {
        return (
          <>
            <Descriptions size="small" column={1} bordered>
              <Descriptions.Item label="Comment">{info.split("Comment: ")?.[1]}</Descriptions.Item>
            </Descriptions>
          </>
        )
      }

    const columns = [
        {
            title: 'PC ID',
            dataIndex: 'pcId',
            sorter: (a, b) => a.pcId.localeCompare(b.pcId),
            render: (text, row) => (
                <Button type="link" onClick={() => handleFieldClick('pcid', text)}>
                  {text}
                </Button>
              )
         },
        { title: 'OS Version', dataIndex: 'osVersion', sorter: (a, b) => Intl.Collator().compare(a.osVer, b.osVer) },
        {
            title: 'App Version',
            dataIndex: 'appVersion',
            sorter: (a, b) => Intl.Collator().compare(a.appVer, b.appVer),
            render: (text, row) => (
                <Button type="link" onClick={() => handleFieldClick('appVer', text)}>
                  {text}
                </Button>
              )
        },
        { title: 'Mode', dataIndex: 'mode', sorter: (a, b) => a.mode.localeCompare(b.mode) },
        { title: 'Game', dataIndex: 'game', sorter: (a, b) => a.game.localeCompare(b.game) },
        { title: 'Event ID', dataIndex: 'eventId', sorter: (a, b) => a.eventId.localeCompare(b.eventId) },
        { title: 'Event Info', dataIndex: 'eventInfo', sorter: (a, b) => a.eventInfo.localeCompare(b.eventInfo) },
        {
            title: 'Kickoff Time',
            dataIndex: 'kickoffTime',
            sorter: (a, b) => new Date(a.kickoffTime) - new Date(b.kickoffTime),
            render: (value) => {
                const date = moment(value)
                return date.isValid() ? date.format('YYYY-MM-DD hh:mm:ss A') : ''
            }
        },
        { title: 'Action', dataIndex: 'action', sorter: (a, b) => a.action.localeCompare(b.action) },
        {
            title: 'Message',
            dataIndex: 'message',
            sorter: (a, b) => Intl.Collator().compare(a.message, b.message),
            render: (value) => {
                if(value !== null && value?.includes("Comment"))
                {
                    return  <>
                    {
                        <span>{value.split("Comment: ")?.[0]}
                        <Popover placement="topRight" title="Bug Report" content={infoContent(value)}>
                        <InfoCircleTwoTone />
                        </Popover>
                        </span>
                    }
                    </>
                }
                return value;
            },
            width: "20%"
        },
        {
            title: 'Timestamp',
            dataIndex: 'logTimestamp',
            sorter: (a, b) => new Date(b.logTimestamp) - new Date(a.logTimestamp),
            defaultSortOrder: 'descend',
            render: (value) => {
                const date = moment(value)
                return date.isValid() ? date.format('YYYY-MM-DD hh:mm:ss A') : ''
            }
        }
    ]

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            loading={isLoading}
            scroll={{ x: 'scroll' }}
            pagination={{
                pageSize: 100,
                showSizeChanger: false
            }}
            rowKey="id"
            className={'emActionLogTable'}
            size="small"
            locale={{
                emptyText: <Empty image={isLoading ? '' : Empty.PRESENTED_IMAGE_SIMPLE}
                    description={isLoading ? '' : 'No record found'} />
            }}
        />
    )
}

import React, { useEffect, useState } from 'react';
import './EsmHelpPageStyle.css';
import { HashLink as Link } from 'react-router-hash-link'
import { useLocation } from 'react-router-dom'
import { Typography, Row, Col, Image } from 'antd';
import { EmVersionService } from '../../services'
import { StopFilled, LoadingOutlined } from '@ant-design/icons'
//import screenshot from './assets/Fifa2020.JPG'

export const EsmHelpPage = (props) => {
    const { Title, Text } = Typography
    const [isAccessKeyValid, setAccessKeyValid] = useState(null)

    var search = useLocation().search
    var pcId = new URLSearchParams(search).get("pcId")
    var accessKey = new URLSearchParams(search).get("accessKey")

    async function IsEsAccessKeyValid() {
        var response = await EmVersionService.isEsAccessKeyValid(pcId, accessKey)
        if (response) {
            return response.result.result
        }
        return false
    }

    useEffect(() => {
        const verifyAccessKey = async () => {
          var isValid = false
          if (localStorage.user != null && localStorage.user !== 'null'){
            isValid = true
            setAccessKeyValid(isValid)
          }
          else{
            isValid = await IsEsAccessKeyValid()
            setAccessKeyValid(isValid)
          }

        }
        verifyAccessKey()
      }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        {isAccessKeyValid != null && isAccessKeyValid ?
            <>
                <body>
                    <div class="container clear">
                        <div class="row wrapper">
                            <div class="sidepanel">
                                <Row>
                                    <Link to="#verify-team-name" smooth><h3>Verify Team Name</h3></Link>
                                </Row>
                                <Row>
                                    <Link to="#start-capture" smooth><h3>Start Capture</h3></Link>
                                </Row>
                                <Row>
                                    <Link to="#rest-capture" smooth><h3>Reset Capture</h3></Link>
                                </Row>
                                <Row>
                                    <Link to="#bug-report" smooth><h3>Bug Report</h3></Link>
                                </Row>
                                <Row>
                                    <Link to="#widget-not-working-correctly" smooth><h3>Widget Not Working Correctly</h3></Link>
                                </Row>
                            </div>
                            <div class="right-col">
                                <h1>E-Sport Manager User Manual</h1>
                                <div id="verify-team-name">
                                    <h2>Verify Team Names</h2>
                                    <ol>
                                        <li>
                                            <h3>Soccer</h3>
                                            {/* <Image src={screenshot} alt="This is a Screenshot1" width={400} /> */}
                                            <p>
                                                When <b>Verify Teams</b> button is clicked please make sure that the <b>game is paused</b> so that the widget can read the team name from the screen.
                                            </p>
                                        </li>
                                        <li>
                                            <h3>Basketball</h3>
                                            <p>
                                                When <b>Verify Teams</b> button is clicked please make sure that the <b>game is paused</b> so that the widget can read the team name from the screen.
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                                <div class="divider" id="start-capture"></div>
                                <h2>Start Capture</h2>
                                <p>
                                    Please click on <b>Start Capture</b> button <b>slightly before</b> the kickoff time.
                                </p>
                                <div class="divider" id="reset-capture"></div>
                                <h2>Reset Capture</h2>
                                <p>
                                    If any unusual reading from the game, click <b>Reset Capture</b> button to reset the reading.
                                </p>
                                <div class="divider" id="bug-report"></div>
                                <h2>Bug Report</h2>
                                <p>
                                    When a bug is encountered, please file a bug report within <b>10mins</b> to ensure the correct set of data is submit for diagnosis.
                                </p>
                                <div class="divider" id="widget-not-working-correctly"></div>
                                <h2>Widget Not Working Correctly</h2>
                                <ol>
                                    <li>
                                        <p>Please manually <b>Close</b>[x] the widget and restart.</p>
                                    </li>
                                    <li>
                                        <p>It is <b>NOT</b> necessary to reinstall the same version of the widget as it won't solve the problem but it deletes all the bug data.</p>
                                    </li>
                                    <li>
                                        <p> File a <b>Bug Report</b> immediately.</p>
                                    </li>
                                </ol>

                                {/* <div class="divider" id="faq"></div>
                    <h2>FAQ</h2>
                    <ol>
                        <li>
                            <p>Question 1</p>
                            <p>Answer 1</p>
                        </li>
                        <li>
                            <p>Question 2</p>
                            <p>Answer 2</p>
                        </li>
                    </ol> */}
                            </div>
                        </div>
                    </div>
                </body>
            </>
            : <Title level={4}>&nbsp;&nbsp;
                {isAccessKeyValid == null
                    ? <><LoadingOutlined /> {'Loading, Please wait...'}</>
                    : <><StopFilled /> {'Unauthorized Access'}</>}
            </Title>
        }
    </>
}

/* eslint-disable no-template-curly-in-string */
const typeTemplate = '${label} is not a valid ${type}'

export const validateMessages = {
  default: 'Validation error on field ${label??name}',
  required: '${label} is required',
  whitespace: '${label} cannot be empty',
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: 'Email address is not valid',
    url: typeTemplate,
    hex: typeTemplate
  }
}

import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Form, Input, Space, Button, PageHeader, Layout } from 'antd'
import { EsmUserService } from '../../services'
import { showSuccessMessage, showErrorMessage } from '../../helpers'
import { formItemLayout } from '../../FormLayout'

export const EsmUserPasswordEditorPage = (props) => {
    const { Content } = Layout;
    let { id } = useParams()
    const initialValues = { id }

    var userId = props.location.state?.userId
    var username = props.location.state?.username

    var listingPageUrl = `/esmUsers/users?userId=${userId}`

    const handleFormSubmit = async (data) => {
        data.TriggeredUserId = userId
        data.Username = username
        const response = await EsmUserService.updateUserPassword(data)
        if (response.isError) showErrorMessage(response.message)
        else showSuccessMessage('Password successfully reset.')
        props.history.push(listingPageUrl)
      }

      const EsmUserPasswordEditorForm = () => {
        return (
            <>
              <Form {...formItemLayout} initialValues={initialValues} onFinish={handleFormSubmit} autoComplete="off">
                <Form.Item name="id" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item label="Password" name="password" rules={[
                    { required: true },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value !== null && value.length < 4) {
                                return Promise.reject(new Error('Password must contain a minimum of 4 characters'))
                            }
                            return Promise.resolve()
                        }
                    })
                    ]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  dependencies={['password']}
                  rules={[
                    { required: true },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        if (value !== null && value.length < 4) {
                          return Promise.reject(new Error('Password must contain a minimum of 4 characters'))
                      }
                        return Promise.reject(new Error('The Password and Confirm Password do not match!'))
                      }
                    })
                  ]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
                <Form.Item label="&nbsp;" colon={false}>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Change Password
                    </Button>
                    <Link to={listingPageUrl}>
                      <Button type="default" htmlType="submit">
                        Cancel
                      </Button>
                    </Link>
                  </Space>
                </Form.Item>
              </Form>
            </>
          )
      }
    
      return (
        <>
            <PageHeader title={"Change Password"} />
            <Content style={{
                margin: '20px',
            }}>
                <EsmUserPasswordEditorForm/>
            </Content>
        </>
    )
      
}


function canViewOwn(role) {
   return role === 'PIC'
}

function canCreate(role) {
    if (role === 'PIC' || role === 'TeamLeader') return false
    else return true
}

function canEdit(role) {
    return role !== 'PIC'
} 

export const EsmUserPermissionService = {
    canViewOwn,
    canCreate,
    canEdit
  }

import React, { useEffect, useState } from 'react'
import { Button, Typography, Row, Col, Descriptions, Table, Card, Tag, Popover, Image, Divider } from 'antd'
import { SyncOutlined, InfoCircleTwoTone, MoreOutlined } from '@ant-design/icons'
import moment from 'moment'
import './DashboardPage.less'

import { InfoService } from '../services'

const { Text } = Typography

export const DashboardPage = (props) => {
  const [virtualSportInfo, setVirtualSportInfo] = useState({})
  const [clientInfo, setClientInfo] = useState([])
  const [esportClientInfo, setEsportClientInfo] = useState([])
  const [esportGrpcClientInfo, setEsportGrpcClientInfo] = useState([])
  const [loading, setLoading] = useState(false)

  const matchesTableColumns = [
    {
      title: 'Match Id',
      dataIndex: 'matchEventId',
      key: 'matchEventId'
    },
    {
      title: 'Match Event',
      dataIndex: 'matchEventInfo',
      key: 'matchEventInfo'
    },
    {
      title: 'Match Status',
      dataIndex: 'matchStatus',
      key: 'matchStatus'
    }
  ]

  const conInfoTableColumns = [
    {
      title: 'Connection Id',
      dataIndex: 'connectionId',
      key: 'connectionId'
    },
    {
      title: 'Channels',
      dataIndex: 'subscribedChannels',
      key: 'subscribedChannels',
      render: (channels) => (
        <span>
          {channels.map((channel) => {
            return (
              <div className="m-1">
                <Tag color="blue" key={channel}>
                  {channel}
                </Tag>
              </div>
            )
          })}
        </span>
      )
    },
    {
      title: 'Connected Since',
      dataIndex: 'connectedSince',
      key: 'connectedSince'
    }
  ]

  const infoContent = (info) => {
    return (
      <>
        <Descriptions size="small" column={1} bordered>
          <Descriptions.Item label="OS Version">{info.osVersion}</Descriptions.Item>
          <Descriptions.Item label="App Version">{info.appVersion}</Descriptions.Item>
          <Descriptions.Item label="IP Address">{info.clientIp}</Descriptions.Item>
          <Descriptions.Item label="Last Game">{info.lastGame}</Descriptions.Item>
          <Descriptions.Item label="Connected Since">{moment(info.connectedSince).format('YYYY-MM-DD hh:mm A')}</Descriptions.Item>
          <Descriptions.Item label="Last Updated">{moment(info.lastUpdated).format('YYYY-MM-DD hh:mm A')}</Descriptions.Item>
        </Descriptions>
      </>
    )
  }

  const recentActionsContent = (recentActions) => {
    return (
      <>
        <div>
          {recentActions.map((info, index) => (
            <p key={index}>
              <Text type="secondary">{moment(info.timestamp).format('YYYY-MM-DD hh:mm:ss A')}</Text> <Tag color="blue">{info.action}</Tag>
            </p>
          ))}
        </div>
      </>
    )
  }

  const lastActionContent = (recentActions) => {
    if (recentActions.length > 0) {
      let lastAction = recentActions[recentActions.length - 1]
      return (
        <div>
          <Tag color="blue">{lastAction.action}</Tag>
        </div>
      )
    } else {
      return <div></div>
    }
  }

  const reloadEsportClientInfo = async () => {
    setLoading(true)
    const response = await InfoService.getEsportClientInfo()
    setEsportClientInfo(response?.result ?? [])
    setLoading(false)
  }

  const reloadEsportGrpcClientInfo = async () => {
    setLoading(true)
    const response = await InfoService.getEsportGrpcClientInfo()
    setEsportGrpcClientInfo(response?.result ?? [])
    setLoading(false)
  }

  useEffect(() => {
    const getVirtualSportInfo = async () => {
      const response = await InfoService.getVirtualSportInfo()
      setVirtualSportInfo(response?.result ?? [])
    }
    const getClientInfo = async () => {
      const response = await InfoService.getClientInfo()
      setClientInfo(response?.result ?? [])
    }
    const getEsportClientInfo = async () => {
      const response = await InfoService.getEsportClientInfo()
      setEsportClientInfo(response?.result ?? [])
    }
    const getEsportGrpcClientInfo = async () => {
      const response = await InfoService.getEsportGrpcClientInfo()
      setEsportGrpcClientInfo(response?.result ?? [])
    }
    getVirtualSportInfo()
    getClientInfo()
    getEsportClientInfo()
    getEsportGrpcClientInfo()
  }, [])
  return (
    <>
      <div className="ant-descriptions-title mt-4 mb-4">
        <span>E-Sport Manager <Tag color="lime">Websocket</Tag></span>
        <Button className="float-right" type="primary" onClick={reloadEsportClientInfo} icon={<SyncOutlined />} loading={loading}>
          Refresh
        </Button>
      </div>
      <Row gutter={[16, 16]}>
        {esportClientInfo
          .sort((a, b) => a.clientIp.localeCompare(b.clientIp))
          .map((info) => (
            <Col key={info.pcId} xs={24} sm={24} md={12} lg={8} xl={6}>
              <div className="esport-client-info-card">
                <div className="esport-client-info">
                  <Image width={80} height={80} preview={false} alt={info.lastGame} src={`images/${info.lastGame}.svg`} fallback="images/esport-manager.svg" />
                  <div className="esport-client-details">
                    <h4 className="ant-list-item-meta-title">
                      <span style={{ marginRight: '8px' }}>
                        {info.pcId} <Text type="secondary">({info.appVersion})</Text>
                      </span>

                      <Popover placement="topRight" title={info.pcId} content={infoContent(info)}>
                        <InfoCircleTwoTone />
                      </Popover>
                    </h4>
                    <div className="ant-list-item-meta-description">{info.clientIp}</div>
                    <div className="ant-list-item-meta-description">{lastActionContent(info.recentActions)}</div>
                  </div>
                </div>
                <div className="esport-client-more-info">
                  <Popover placement="topRight" title={`${info.pcId} Recent Actions`} content={recentActionsContent(info.recentActions)} trigger="click">
                    <MoreOutlined />
                  </Popover>
                </div>
              </div>
            </Col>
          ))}
      </Row>
      <Divider orientation="left" />
      <div className="ant-descriptions-title mt-4 mb-4">
        <span>E-Sport Manager <Tag color="lime">gRPC</Tag></span>
        <Button className="float-right" type="primary" onClick={reloadEsportGrpcClientInfo} icon={<SyncOutlined />} loading={loading}>
          Refresh
        </Button>
      </div>
      <Row gutter={[16, 16]}>
        {esportGrpcClientInfo
          .sort((a, b) => a.clientIp.localeCompare(b.clientIp))
          .map((info) => (
            <Col key={info.pcId} xs={24} sm={24} md={12} lg={8} xl={6}>
              <div className="esport-client-info-card">
                <div className="esport-client-info">
                  <Image width={80} height={80} preview={false} alt={info.lastGame} src={`images/${info.lastGame}.svg`} fallback="images/esport-manager.svg" />
                  <div className="esport-client-details">
                    <h4 className="ant-list-item-meta-title">
                      <span style={{ marginRight: '8px' }}>
                        {info.pcId} <Text type="secondary">({info.appVersion})</Text>
                      </span>

                      <Popover placement="topRight" title={info.pcId} content={infoContent(info)}>
                        <InfoCircleTwoTone />
                      </Popover>
                    </h4>
                    <div className="ant-list-item-meta-description">{info.clientIp}</div>
                    <div className="ant-list-item-meta-description">{lastActionContent(info.recentActions)}</div>
                  </div>
                </div>
                <div className="esport-client-more-info">
                  <Popover placement="topRight" title={`${info.pcId} Recent Actions`} content={recentActionsContent(info.recentActions)} trigger="click">
                    <MoreOutlined />
                  </Popover>
                </div>
              </div>
            </Col>
          ))}
      </Row>
      <Divider orientation="left" />
      <div className="ant-descriptions-title mt-4 mb-4"></div>
      <Row gutter={16}>
        <Col span={8}>
          <Descriptions title="Season Info" size="small" bordered>
            <Descriptions.Item key="seasonId" label="SeasonId" span={3}>
              {virtualSportInfo.seasonId}
            </Descriptions.Item>
            <Descriptions.Item key="simSeasonId" label="Sim SeasonId" span={3}>
              {virtualSportInfo.simSeasonId}
            </Descriptions.Item>
            <Descriptions.Item key="seasonName" label="Season Name" span={3}>
              {virtualSportInfo.seasonName}
            </Descriptions.Item>
            <Descriptions.Item key="seasonStart" label="Season Start" span={3}>
              {virtualSportInfo.seasonStart}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={8}>
          <Descriptions title="Current Round Info" size="small" bordered>
            <Descriptions.Item key="roundId" label="RoundId" span={3}>
              {virtualSportInfo.roundId}
            </Descriptions.Item>
            <Descriptions.Item key="roundStatus" label="Round Status" span={3}>
              {virtualSportInfo.roundStatus}
            </Descriptions.Item>
            <Descriptions.Item key="roundStart" label="Round Start" span={3}>
              {virtualSportInfo.roundStart}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Divider orientation="left" />
        <Col span={24} className="mt-4">
          <div className="ant-descriptions-title">Current Match Info</div>
          <Table columns={matchesTableColumns} dataSource={virtualSportInfo.roundMatches} pagination={false} size="small" className="mt-4" bordered></Table>
        </Col>
      </Row>
      <Divider orientation="left" />
      <div className="ant-descriptions-title mt-4 mb-4">Subscriber Info</div>
      <Row gutter={16}>
        {clientInfo.map((info) => (
          <Col span={12}>
            <Card title={info.clientId} size="small" bodyStyle={{ padding: '0' }}>
              <Table columns={conInfoTableColumns} size="small" dataSource={info.subscriberConnectionInfos} pagination={false} bordered></Table>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Form, Input, Switch, Space, Button } from 'antd'

import { showSuccessMessage, showErrorMessage } from '../../helpers'
import { formItemLayout } from '../../FormLayout'
import { OddsEngineSettingService } from '../../services'


export const OddsEngineSettingEditorPage = (props) => {
    const [editorForm] = Form.useForm()
    const [saving, setSaving] = useState(false)

    let { id } = useParams()

    useEffect(() => {
        const getInstance = async (id) => {
            let data = { status: true }
            if (id) {
                const response = await OddsEngineSettingService.get(id)
                if (response.isError) showErrorMessage(response.message)
                data = { instanceName: response?.result.instanceName, status: response?.result.status === 'Active'? true : false}
            }
            editorForm.setFieldsValue(data)
        }
        getInstance(id)
    }, [id, editorForm])


    const handleFormSubmit = async (data) => {
        setSaving(true)
        if (data.instanceName) {
            let value = { instanceName: data.instanceName, status: data.status? 'Active' : 'Inactive'}
            let result = await OddsEngineSettingService.update(value)
            if (result.isError) showErrorMessage(`Failed to update Odds Engine intsance. ${result.message}`)
            else showSuccessMessage('Odds Engine instance successfully updated.')
        }
        setSaving(false)
        props.history.push('/oddsEngine/setting')
    }

    return (
        <>
            <Form {...formItemLayout} form={editorForm} onFinish={handleFormSubmit} autoComplete="off">
                <Form.Item name="id" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item label="Instance Name" name="instanceName">
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item label="Is Active" name="status" valuePropName="checked" initialValue={false}>
                    <Switch />
                </Form.Item>
                <Form.Item label="&nbsp;" colon={false}>
                    <Space>
                        <Button type="primary" htmlType="submit" loading={saving}>
                            Save
                        </Button>
                        <Link to="/oddsEngine/setting">
                            <Button type="default">Cancel</Button>
                        </Link>
                    </Space>
                </Form.Item>
            </Form>
        </>
    )
}

import React, { useState } from 'react'
import { Form, Row, Col, Input, DatePicker, Select, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const { Option } = Select

export const EsmReportSearchForm = ({ onSearch, searchCriteriaOptions, selectedSearchCriteria, setSelectedSearchCriteria }) => {
    var game = searchCriteriaOptions ? searchCriteriaOptions.games : {}
    var mode = searchCriteriaOptions ? searchCriteriaOptions.modes : {}
    var action = searchCriteriaOptions ? searchCriteriaOptions.actions : {}

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    form.setFieldsValue(
        {
            pcid: selectedSearchCriteria.pcid,
            appVer: selectedSearchCriteria.appVer,
            reportStart: selectedSearchCriteria.reportStart,
            reportEnd: selectedSearchCriteria.reportEnd,
            message: selectedSearchCriteria.message,
            event: selectedSearchCriteria.event
        })

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
            md: { span: 9 },
            lg: { span: 10 },
            xxl: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
            md: { span: 15 },
            lg: { span: 14 },
            xxl: { span: 16 }
        },
        labelAlign: 'left'
    }
    const colProps = { xs: { span: 24 }, md: { span: 12 }, lg: { span: 8 }, xl: { span: 6 } }
    const labelCol = { flex: '130px' }

    function onSelect(selectedValue, field) {
        if (selectedValue === '') {
          form.setFieldsValue(field)
        }
      }

    function onChange(field){
       var val = form.getFieldValue(field)
       setSelectedSearchCriteria(prevState => ({
            ...prevState,
            [field]: val
        }))
    }

    return (
        <Form {...formItemLayout} form={form}>
            <Row gutter={(8, 8)}>
                <Col {...colProps}>
                    <Form.Item name="reportStart" label={'Date From'} labelCol={labelCol}>
                        <DatePicker onChange={x => onChange('reportStart')}/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item name="reportEnd" label={'Date To'} labelCol={labelCol}>
                        <DatePicker onChange={x => onChange('reportEnd')}/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item name="game" label={'Game'} labelCol={labelCol}>
                        <Select placeholder={'Select a Game'} mode="multiple" onSelect={(x) => onSelect(x, { category: Object.entries(game).map(([key, value]) => value) })} allowClear>
                                {Object.entries(game).map(([key, value]) => (
                                <Option value={value} key={key}>
                                    {value}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={{ span: 0 }} md={{ span: 0 }} lg={{ span: 0 }} xl={{ span: 6 }}></Col>

                <Col {...colProps}>
                    <Form.Item name="mode" label={'Mode'} labelCol={labelCol}>
                        <Select placeholder={'Select a Mode'} mode="multiple" onSelect={(x) => onSelect(x, { category: Object.entries(mode).map(([key, value]) => value) })} allowClear>
                            {Object.entries(mode).map(([key, value]) => (
                                <Option value={value} key={key}>
                                    {value}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item name="pcid" label={'PC ID'} labelCol={labelCol}>
                        <Input placeholder={'PC ID'} onChange={x => onChange('pcid')} allowClear/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item name="appVer" label={'App Version'} labelCol={labelCol}>
                        <Input placeholder={'App version'} onChange={x => onChange('appVer')} allowClear/>
                    </Form.Item>
                </Col>
                <Col xs={{ span: 0 }} md={{ span: 0 }} lg={{ span: 0 }} xl={{ span: 6 }}></Col>

                <Col {...colProps}>
                    <Form.Item name="action" label={'Action'} labelCol={labelCol}>
                        <Select placeholder={'Select an Action'} mode="multiple" onSelect={(x) => onSelect(x, { category: Object.entries(action).map(([key, value]) => value) })} allowClear>
                            {Object.entries(action).map(([key, value]) => (
                                <Option value={value} key={key}>
                                    {value}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item name="message" label={'Message'} labelCol={labelCol}>
                        <Input placeholder={'Message'} onChange={x => onChange('message')} allowClear/>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item name="event" label={'Event'} labelCol={labelCol}>
                        <Input placeholder={'Event Id/Event Info'} onChange={x => onChange('event')} allowClear/>
                    </Form.Item>
                </Col>
                <Col flex={'auto'} xl={{ span: 0 }}></Col>
                <Col flex={'none'} xl={{ span: 6 }}>
                    <Form.Item>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={async () => {
                                setLoading(true)
                                await onSearch(form.getFieldsValue())
                                setLoading(false)
                            }}
                            icon={<SearchOutlined />}
                        >
                            {'Search'}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

// EsmReportSearchForm.propTypes = {
//     searchCriteria: PropTypes.object,
//     onSearch: PropTypes.func.isRequired
// }

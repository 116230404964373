import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { EsmReportSearchForm, EsmPieChart } from '.'
import { ActionLogService } from '../../services'
import moment from 'moment'

export const EsmSummaryReportPage = (props) => {
    const [searchCriteriaOptions, setSearchCriteriaOptions] = useState(null)
    const [graphData, setGraphData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedSearchCriteria, setSelectedSearchCriteria] = useState({'pcid': '', 'appVer': ''})

    async function onSearch(searchCriteria) {
        var graphData = await GetGraphData(searchCriteria)
        setGraphData(graphData)
    }

    async function GetGraphData(searchCriteria) {
        var criteria = {
          FromDate: searchCriteria.reportStart ? moment(searchCriteria.reportStart).format('yyyy-MM-DD') : '',
          ToDate: searchCriteria.reportEnd ? moment(searchCriteria.reportEnd).format('yyyy-MM-DD') : '',
          PcId: searchCriteria.pcid,
          Mode: searchCriteria.mode,
          Game: searchCriteria.game,
          Action: searchCriteria.action,
          AppVersion: searchCriteria.appVer,
          Message: searchCriteria.message,
          Event: searchCriteria.event
        }

        var queryString = objectToQueryString(criteria)
        setIsLoading(true)
        var response = await ActionLogService.getActionLogSummaries(queryString)
        setIsLoading(false)
        if (response) {
          return response.result
        }
        return null
      }

    async function GetSearchCriteria() {
        setIsLoading(true)
        var response = await ActionLogService.getSearchCriteria()
        setIsLoading(false)
        if (response) {
          return response.result
        }
        return null
      }

      function objectToQueryString(obj) {
        var str = []
        for (var p in obj) {
          if (obj[p]) {
            if (obj.hasOwnProperty(p)) {
              if (Array.isArray(obj[p])) {
                for (var arr in obj[p]) {
                  str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p][arr]))
                }
              } else {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
              }
            }
          }
        }

        return str.join('&')
      }

    useEffect(() => {
        const getDropDownSearchCriteria = async () => {
          var searchCriteriaList = await GetSearchCriteria()
          setSearchCriteriaOptions(searchCriteriaList)
        }
        getDropDownSearchCriteria()
      }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <EsmReportSearchForm onSearch={onSearch} searchCriteriaOptions={searchCriteriaOptions} selectedSearchCriteria={selectedSearchCriteria} setSelectedSearchCriteria={setSelectedSearchCriteria}/>
            <Row>
                <Col flex={2}><EsmPieChart GraphTitle={'Action Type graph'} GraphData={graphData != null ? graphData.actionGraphData : []}/></Col>
                <Col flex={3}><EsmPieChart GraphTitle={'App Version graph'} GraphData={graphData != null ? graphData.appVersionGraphData : []}/></Col>
            </Row>
        </>
    )
}

import React, { createContext, useState, useEffect } from 'react'
import { AuthService } from '../../services'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({})

  useEffect(() => {
    AuthService.currentUser.subscribe((currentUser) => {
      setUser(currentUser)
    })
  }, [user])

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
}

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { PageHeader, Table, Tag, Button, Typography } from 'antd'
import { PlusOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import moment from 'moment'

import { EmVersionService } from '../../services'

export const EsmPackageHistoriesPage = (props) => {
  const { Text } = Typography
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    const columns = [
        {
          title: 'Package Version',
          dataIndex: 'packageVersion',
          sorter: (a, b) => Intl.Collator().compare(a.packageVersion, b.packageVersion),
          render: (text, row) => <Link to={`/esmPackage/package/${row.id}`}>{text}</Link>
        },
        {
          title: 'Changelog',
          dataIndex: 'changelog',
          sorter: (a, b) => a.changelog.localeCompare(b.changelog),
          render: (changeLog) =>
            <Text style={{ whiteSpace: "pre-line" }}>
              {changeLog}
            </Text>
        },
        {
          title: 'Affected System',
          dataIndex: 'affectedSystemList',
          sorter: (a, b) => a?.affectedSystemList?.localeCompare(b?.affectedSystemList),
          render: (affectedSystemList) =>
            affectedSystemList?.map((number) => <Tag color="cyan">{number}</Tag>)
        },
        {
          title: 'Active',
          key: 'isAvtive',
          dataIndex: 'isActive',
          sorter: (a, b) => a.isActive - b.isActive,
          render: (isActive) => (isActive ? <CheckCircleFilled style={{ color: '#52c41a', fontSize: '18px' }} /> : <CloseCircleFilled style={{ color: '#8c8c8c', fontSize: '18px' }} />)
        },
        {
          title: 'Created Date',
          dataIndex: 'createdDate',
          key: 'createdDate',
          sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
          render: (value) => {
            const date = moment(value)
            return date.isValid() ? date.format('YYYY-MM-DD hh:mm A') : ''
          },
          defaultSortOrder: 'descend'
        },
        {
          title: 'Updated Date',
          dataIndex: 'updatedDate',
          key: 'updatedDate',
          sorter: (a, b) => new Date(a.updatedDate) - new Date(b.updatedDate),
          render: (value) => {
            const date = moment(value)
            return date.isValid() ? date.format('YYYY-MM-DD hh:mm A') : ''
          }
        },
        { title: 'UpdatedBy', dataIndex: 'updatedBy', sorter: (a, b) => a.updatedBy.localeCompare(b.updatedBy) },
      ]

      useEffect(() => {
        const getEmPackage = async () => {
          const response = await EmVersionService.getEmVersions(null, null, null)
          setData(response?.result ?? [])
          setLoading(false)
        }
        getEmPackage()
      }, [])

      return (
        <>
          <PageHeader
            extra={[
              <Link key="new-package-link" to="/esmPackage/package/new">
                <Button icon={<PlusOutlined />} type="primary">
                  New
                </Button>
              </Link>
            ]}
          />
          <Table rowKey="id" columns={columns} dataSource={data} size="small" loading={loading} bordered />
        </>
      )
    }

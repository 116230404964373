import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Form, Input, Space, Button } from 'antd'
import { formItemLayout } from '../../FormLayout'

import { showSuccessMessage, showErrorMessage } from '../../helpers'
import { UserService } from '../../services'

export const ResetPasswordPage = (props) => {
  const handleFormSubmit = async (data) => {
    const response = await UserService.resetPassword(data)
    if (response.isError) showErrorMessage(response.message)
    else showSuccessMessage('Password successfully reset.')
    props.history.push('/users')
  }

  let { id } = useParams()
  const initialValues = { id }

  return (
    <>
      <Form {...formItemLayout} initialValues={initialValues} onFinish={handleFormSubmit} autoComplete="off">
        <Form.Item name="id" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{ required: true }]}>
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The Password and Confirm Password do not match!'))
              }
            })
          ]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Form.Item label="&nbsp;" colon={false}>
          <Space>
            <Button type="primary" htmlType="submit">
              Reset
            </Button>
            <Link to="/users">
              <Button type="default" htmlType="submit">
                Cancel
              </Button>
            </Link>
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}

import { Descriptions, Col, Row, Typography  } from 'antd'

const { Text } = Typography

const basicOddsStructure = (homeOdds, awayOdds) => {
    return (
      <>
            <Row><Col className="gutter-row" span={18}><Text style={{ color: "blue" }}>{homeOdds}</Text></Col></Row>
            <Row><Col className="gutter-row" span={18}>{awayOdds}</Col></Row>
      </>
    )
}

const handicapOddsStructure = (oeOdds, ouOdds) => {
    return (
        <>
            <Descriptions layout='vertical' column={2} size="small" bordered>
                <Descriptions.Item label="OE">
                        {oeOddsStructure(
                            oeOdds?.homeOddEven?.odd?.toFixed(4) ?? '-',
                            oeOdds?.awayOddEven?.odd?.toFixed(4) ?? '-',
                            oeOdds?.homeOddEven?.even?.toFixed(4) ?? '-',
                            oeOdds?.awayOddEven?.even?.toFixed(4) ?? '-')}
                </Descriptions.Item>
                <Descriptions.Item label="OU">

                        {ouOddsStucture(
                            ouOdds?.homeHandicapOdds?.handicap?.toFixed(4) ?? '-',
                            ouOdds?.homeHandicapOdds?.over?.toFixed(4) ?? '-',
                            ouOdds?.homeHandicapOdds?.under?.toFixed(4) ?? '-',
                            ouOdds?.awayHandicapOdds?.handicap?.toFixed(4) ?? '-',
                            ouOdds?.awayHandicapOdds?.over?.toFixed(4) ?? '-',
                            ouOdds?.awayHandicapOdds?.under?.toFixed(4) ?? '-')}
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

const oeOddsStructure = (oddHome, oddAway, evenHome, evenAway) => {
    return (
        <>
            <Descriptions layout='horizontal' column={1} size="small" bordered>
                <Descriptions.Item>
                    <Row gutter={24}>
                        <Col className="gutter-row" span={12}>Odd</Col>
                        <Col className="gutter-row" span={12}><Text style={{ color: "blue" }}>{oddHome}</Text></Col>
                    </Row>
                    <Row gutter={24}>
                        <Col className="gutter-row" span={12}></Col>
                        <Col className="gutter-row" span={12}>{oddAway}</Col>
                    </Row>
                </Descriptions.Item>
                <Descriptions.Item>
                    <Row gutter={24}>
                        <Col className="gutter-row" span={12}>Even</Col>
                        <Col className="gutter-row" span={12}><Text style={{ color: "blue" }}>{evenHome}</Text></Col>
                    </Row>
                    <Row gutter={24}>
                        <Col className="gutter-row" span={12}></Col>
                        <Col className="gutter-row" span={12}>{evenAway}</Col>
                    </Row>
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

const ouOddsStucture = (homeHand, homeOver, homeUnder, awayHand, awayOver, awayUnder) => {
    return (
        <>
            <Descriptions layout='horizontal' column={1} size="small" bordered>
                <Descriptions.Item>
                    <Row gutter={24}>
                        <Col className="gutter-row" span={8}><Text style={{ color: "blue" }}>{homeHand}</Text></Col>
                        <Col className="gutter-row" span={8}>Over</Col>
                        <Col className="gutter-row" span={8}><Text style={{ color: "blue" }}>{homeOver}</Text></Col>
                    </Row>
                    <Row gutter={24}>
                        <Col className="gutter-row" span={8}></Col>
                        <Col className="gutter-row" span={8}>Under</Col>
                        <Col className="gutter-row" span={8}><Text style={{ color: "blue" }}>{homeUnder}</Text></Col>
                    </Row>
                </Descriptions.Item>
                <Descriptions.Item>
                    <Row gutter={24}>
                        <Col className="gutter-row" span={8}>{awayHand}</Col>
                        <Col className="gutter-row" span={8}>Over</Col>
                        <Col className="gutter-row" span={8}>{awayOver}</Col>
                    </Row>
                    <Row gutter={24}>
                        <Col className="gutter-row" span={8}></Col>
                        <Col className="gutter-row" span={8}>Under</Col>
                        <Col className="gutter-row" span={8}>{awayUnder}</Col>
                    </Row>
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export const SportbookOddsInfoCell = (props) => {
    var odds = props.value?.odds ?? []
    var matchInfo = props.value?.matchInfo ?? []

    var Xth = matchInfo?.over !== null ? "(Over " + (matchInfo?.over < 20 ? matchInfo?.over + 1 : matchInfo?.over) + ")" : "";
    return (
        <>
            <Descriptions layout='vertical' column={4} bordered>
                <Descriptions.Item label="WhoWinTheToss" style={{width: "10%"}}>
                    {basicOddsStructure(odds?.homeGe?.toFixed(4) ?? '-', odds?.awayGe?.toFixed(4) ?? '-')}
                </Descriptions.Item>
                <Descriptions.Item label="MoneyLine" style={{width: "10%"}}>
                    {basicOddsStructure(
                        odds?.moneyLineOdds?.homeOdds !== null ? typeof odds?.moneyLineOdds?.homeOdds !== 'string' ? !isNaN(parseFloat(odds?.moneyLineOdds?.homeOdds)) ? odds?.moneyLineOdds?.homeOdds?.toFixed(4) : odds?.moneyLineOdds?.homeOdds : odds?.moneyLineOdds?.homeOdds : '-',
                        odds?.moneyLineOdds?.awayOdds !== null ? typeof odds?.moneyLineOdds?.awayOdds !== 'string' ? !isNaN(parseFloat(odds?.moneyLineOdds?.awayOdds)) ? odds?.moneyLineOdds?.awayOdds?.toFixed(4) : odds?.moneyLineOdds?.awayOdds : odds?.moneyLineOdds?.awayOdds : '-') }
                </Descriptions.Item>
                <Descriptions.Item label="TotalRun" style={{width: "40%"}}>
                    {handicapOddsStructure(odds?.totalRunOEOdds ?? [], odds?.totalRunOUOdds ?? [])}
                </Descriptions.Item>
                <Descriptions.Item label={"XO" && Xth} style={{width: "40%"}}>
                    {handicapOddsStructure(odds?.xooeOdds ?? [], odds?.xoouOdds ?? [])}
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

import axios from 'axios'

const getAll = async () => {
    var response = await axios.get('OddsEngineSetting/listOddsEngineInstances').catch(console.log)
    return response?.data
}

const get = async (id) => {
    try {
        const response = await axios.get(`OddsEngineSetting/getOddsEngineInstance?id=${id}`)
        return response?.data
    } catch (error) {
        return error.response?.data
    }
}

const update = async (data) => {
    try {
        const response = await axios.put('OddsEngineSetting/updateOddsEngineStatus', data)
        return response?.data
    } catch (error) {
        return error.response?.data
    }
}

export const OddsEngineSettingService = {
    getAll,
    get,
    update
}

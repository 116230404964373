import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Input, Space, Button } from 'antd'

import { showSuccessMessage, showErrorMessage } from '../../helpers'
import { formItemLayout } from '../../FormLayout'
import { AuthService } from '../../services'

export const ProfilePage = (props) => {
  const [form] = Form.useForm()

  useEffect(() => {
    const getProfile = async (userId) => {
      let response = await AuthService.getProfile()
      if (response.isError) showErrorMessage(response.message)
      form.setFieldsValue(response?.result)
    }
    getProfile()
  }, [form])

  async function handleFormSubmit(data) {
    const response = await AuthService.updateProfile(data)
    if (response.isError) showErrorMessage(response.message)
    else showSuccessMessage('Profile successfully updated.')
    props.history.push('/')
  }

  return (
    <>
      <Form {...formItemLayout} form={form} onFinish={handleFormSubmit}>
        <Form.Item label="Username" name="userName">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Full Name" name="displayName" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{ type: 'email' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="&nbsp;" colon={false}>
          <Space>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Link to="/">
              <Button type="default">Cancel</Button>
            </Link>
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}

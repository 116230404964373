import axios from 'axios'

const getStorageInfo = async (env) => {
    var response = await axios.get(`EsmUtility/storageInfo?env=${env}`).catch(console.log)
  return response?.data
}

const getFiles = async (env) => {
    var response = await axios.get(`EsmUtility/listFiles?env=${env}`).catch(console.log)
    return response?.data
  }

const deleteFiles = async (env, fileIds) => {
    var response = await axios.post(`EsmUtility/deleteFiles?env=${env}`, fileIds).catch(console.log)
  return response?.data
}

export const EsmUtilityService = {
    getStorageInfo,
    getFiles,
    deleteFiles
  }

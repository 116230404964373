import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Form, Input, Switch, Space, Button, Select, notification, Popconfirm } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { formItemLayout } from '../../FormLayout'
import { EmVersionService } from '../../services'

const { Option } = Select

export const EsmPackageEditorPage = (props) => {

  const { TextArea } = Input
  const [isNewPackage, setIsNewPackage] = useState(true)
  const [packageForm] = Form.useForm()
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
    const [selectedAffectedSystems, setSelectedAffectedSystems] = useState([])

  let { id } = useParams()

  useEffect(() => {
    const getEmPackage = async (pacakageId) => {
      let data = { isActive: true }
      if (pacakageId) {
        setIsNewPackage(false)
        var response = await EmVersionService.getEmPackage(pacakageId)
        if (response.isError) showErrorMessage(response.message)
        data = response?.result
      }

      var resp = await EmVersionService.getGameOptions()
      setSelectedAffectedSystems(resp?.result)

      packageForm.setFieldsValue(data)
    }
    getEmPackage(id)
  }, [id, packageForm])

  const handleFormSubmit = async (pacakage) => {
    setSaving(true)
    if (id) {
      let result = await EmVersionService.updateEmPackage(pacakage)
      if (result.isError) showErrorMessage(`Failed to update EM package. ${result?.message}`)
      else showSuccessMessage('EM package successfully updated.')
    } else {
      let result = await EmVersionService.createEmPackage(pacakage)
      if (result.isError) showErrorMessage(`Failed to create EM package. ${result?.message}`)
      else showSuccessMessage('EM package successfully created.')
    }
    setSaving(false)
    props.history.push('/esmPackage/package')
  }

  const handleDelete = async () => {
    setDeleting(true)
    let result = await EmVersionService.deleteEmPackage(id)
    if (result.isError) showErrorMessage(result?.data?.message ?? 'Failed to delete EM package.')
    else showSuccessMessage('EM package successfully deleted.')
    setDeleting(false)
    props.history.push('/esmPackage/package')
  }

  const showSuccessMessage = (message) => notification['success']({ message: message, placement: 'bottomRight' })
  const showErrorMessage = (message) => notification['error']({ message: message, placement: 'bottomRight' })

  const onAffectedSystemsChanged = async (selectedSystems) =>  {
    console.log(selectedSystems)
    if (selectedSystems && selectedSystems.includes('All')) {
      var allOption = ['All']
      setSelectedAffectedSystems(allOption)
      packageForm.setFieldsValue({ affectedSystemList: allOption })
    } else {
      var resp = await EmVersionService.getGameOptions()
      setSelectedAffectedSystems(resp?.result)
    }
  }

  return (
    <Form {...formItemLayout} form={packageForm} onFinish={handleFormSubmit} autoComplete="off">
      {!isNewPackage && (
        <Form.Item name="id" hidden={true}>
          <Input />
        </Form.Item>
      )}
      <Form.Item label="Package Version" name="packageVersion"
          rules={[{
            required: true,
            pattern: new RegExp(/\bv(\d+\.)+(\d+\.)+(\*|\d+)$/g),
            message: 'Please input correct package version. Eg: v1.0.0'
            }]}>
        <Input style={{ width: 100 }}/>
      </Form.Item>
      <Form.Item label="Changelog" name="changelog" rules={[{ required: true }]}>
        <TextArea rows={4} style={{ height: 200, maxHeight: 200, minWidth: 800  }}/>
      </Form.Item>
      <Form.Item label="Download Url" name="downloadUrl" rules={[
        { required: true},
        { type: "url", message: "Please input a valid url. Eg: https://www.google.com/" }]}>
        <Input style={{ width: 800 }} />
      </Form.Item>
       <Form.Item label="Affected System" name="affectedSystemList">
        <Select
          placeholder={'Select a system'}
          mode="multiple"
          onChange={onAffectedSystemsChanged}
          allowClear
          style={{ width: 800 }}>
                <Option value="All" key="All" style={{ visibility: Object.keys(selectedAffectedSystems).length > 0 ? '' : 'hidden' }}>
                  {('All')}
                </Option>
          {Object.entries(selectedAffectedSystems)?.map(([key, value]) => (
            <Option value={key} key={key} disabled={selectedAffectedSystems.length > 0 ? selectedAffectedSystems.includes('All') : false}>
              {(value)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Note" name="note" >
      <TextArea rows={4} style={{ height: 200, maxHeight: 200, minWidth: 800 }}/>
      </Form.Item>
      <Form.Item label="Active" name="isActive" valuePropName="checked" initialValue={false}>
        <Switch />
      </Form.Item>
           <Form.Item label="&nbsp;" colon={false}>
        <Space>
          <Button type="primary" htmlType="submit" loading={saving}>
            Save
          </Button>
          <Link to="/esmPackage/package">
            <Button type="default">Cancel</Button>
          </Link>
          {!isNewPackage && (
            <Popconfirm placement="top" title="Are you sure you want to delete?" onConfirm={handleDelete} icon={<QuestionCircleOutlined style={{ color: 'red' }} />} okText="Yes" cancelText="No">
              <Button type="danger" loading={deleting}>
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      </Form.Item>
    </Form>
  )


}

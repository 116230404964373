import { localsync } from 'localsync'

export const SyncController = (channel, handler) => {
  const createSync = localsync({ channel: channel })
  const syncController = createSync(
    (data) => data || null,
    (current, previous, url) => handler(current, previous, url)
  )
  syncController.start(true)
  if (syncController.isFallback) console.warn('browser does not support local storage synchronization, falling back to cookie synchronization.')
  return syncController
}

import React, { useState, useEffect } from 'react'
import { Table, Col, Row, Statistic, Form, Select, Input, Button } from 'antd'
import './oddsEngineMonitoring.css'
import { EventMatchInfoCell, SportbookOddsInfoCell, ExchangeOddsInfoCell } from './'
import { SearchOutlined } from '@ant-design/icons'

import { OddsEngineMonitoringService } from '../../services'

const { Option } = Select
const labelCol = { flex: '100px' }
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 9 },
    lg: { span: 10 },
    xxl: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
    md: { span: 15 },
    lg: { span: 14 },
    xxl: { span: 16 }
  },
  labelAlign: 'left'
}

export const OddsEngineMonitoringPage = (props) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(true)
  const [selectedMatchPeriod, setSelectedMatchPeriod] = useState([])
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [selectedSource, setSelectedSource] = useState('Sportbook')

  const sources = ['Sportbook', 'Bookmaker/Fancy']

  useEffect(() => {
    const getMatchPeriod = async () => {
      var resp = await OddsEngineMonitoringService.getECricketFormulaInning()
      setSelectedMatchPeriod(resp?.result ?? [])
    }

    const getOddsEngineMonitoringInfo = async () => {
      var resp = await OddsEngineMonitoringService.getECricketSportbookOddsEngineMonitoringInfo()
      setData(resp?.result?.currentActiveMatches ?? [])
      setAllData(resp?.result ?? [])
      setLoading(false)
    }

    getMatchPeriod()
    getOddsEngineMonitoringInfo()
  }, [form])

  const onMatchPeriodChanged = async (selectedMatchPeriod) => {
    if (selectedMatchPeriod && selectedMatchPeriod.includes('All')) {
      var allOption = ['All']
      setSelectedMatchPeriod(allOption)
      form.setFieldsValue({ matchPeriod: allOption })
    } else {
      var resp = await OddsEngineMonitoringService.getECricketFormulaInning()
      setSelectedMatchPeriod(resp?.result ?? [])
    }
    }

  async function getOddsEngineMonitoringInfo(source) {
    var resp = source === sources[0] ? await OddsEngineMonitoringService.getECricketSportbookOddsEngineMonitoringInfo() :
          await OddsEngineMonitoringService.getECricketExchangeOddsEngineMonitoringInfo()
    return resp
}

  async function onSearch(searchCriteria) {
    var periods = Array.from(searchCriteria.matchPeriod ?? [])
    var eventId = searchCriteria.eventId ?? ''
    var resp  = await getOddsEngineMonitoringInfo(searchCriteria.source)
    var filteredMatches = Array.from(allData?.currentActiveMatches) ?? []

    setSelectedSource(searchCriteria.source)

    if (eventId !== null && eventId !== '') {
      filteredMatches = filteredMatches.filter(d => d.eventLinkId.toString() === eventId)
    }

    if (periods.length !== 0) {
      if (!periods.includes('All')) {
        var availMatches = []
        periods.forEach(p => {
          var matches  = filteredMatches.map(m => {
            if (m.matchInfoOdds.matchInfo.inning.toLowerCase() === p.toLowerCase())
              return m
            return null
          }).filter(x => x !== null)

          matches?.forEach(s => availMatches.push(s))
        });

        filteredMatches = availMatches
      }
    }

    var value = JSON.stringify(filteredMatches) === '[[]]'? null : filteredMatches
    setData(resp?.result?.currentActiveMatches ?? [])
    setAllData(resp?.result ?? [])
    setLoading(false)
  }

  const StaticticsLayout = (value) => {
    return (
      <>
        <Row>
          <Col span={6}>
            <Statistic title="Match Info in Queue" value={value?.numOfMatchInfoInQueue ?? ""} />
          </Col>
          <Col span={6}>
            <Statistic title="Prematch Matches" value={value?.numOfPreMatches ?? ""} />
          </Col>
          <Col span={6}>
            <Statistic title="InRunning Matches" value={value?.numOfInRunningMatches ?? ""} />
          </Col>
        </Row>
      </>
    )
  }

  const columns = [
    {
      title: 'Event & Match Info',
      key: 'eventMatchInfo',
      // dataIndex: 'matchInfoOdds',
      sorter: (a, b) =>  a.matchInfoOdds.matchInfo.kickoffTime.localeCompare(b.matchInfoOdds.matchInfo.kickoffTime),
      defaultSortOrder: 'descend',
      render: (miOdds) =>
      (
        <EventMatchInfoCell value={miOdds ?? []}></EventMatchInfoCell>
      ),
    },
    {
      title: 'Odds',
      key: 'odds',
      dataIndex: 'matchInfoOdds',
      //sorter: (a, b) => b.matchInfoOdds.matchInfo.kickoffTime.localeCompare(a.matchInfoOdds.matchInfo.kickoffTime),
      render: (miOdds) =>
      (
        selectedSource === 'Sportbook' 
        ? <SportbookOddsInfoCell value={miOdds ?? []}></SportbookOddsInfoCell> 
        : <ExchangeOddsInfoCell value={miOdds ?? []}></ExchangeOddsInfoCell>
      ),
    }
  ]

  const searchForm = () => {
    return (
      <>
        <Form {...formItemLayout} form={form}>
          <Row gutter={(16, 16)}>
            <Col span={7}>
              <Form.Item name="eventId" label={'Event ID'} labelCol={labelCol}>
                <Input placeholder={'Event ID'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="matchPeriod" label={'Match Period'} labelCol={labelCol}>
                <Select
                  placeholder={'Select a period'}
                  mode="multiple"
                  onChange={onMatchPeriodChanged}
                  allowClear>
                  <Option value="All" key="All" style={{ visibility: Object.keys(selectedMatchPeriod).length > 0 ? '' : 'hidden' }}>
                    {('All')}
                  </Option>
                  {Object.entries(selectedMatchPeriod)?.map(([key, value]) => (
                    <Option value={value} key={key} disabled={selectedMatchPeriod.length > 0 ? selectedMatchPeriod.includes('All') : false}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="source" label={'Source'} labelCol={labelCol}>
                <Select placeholder={'Select a source'} defaultValue={"Sportbook"} allowClear>
                  {Object.entries(sources)?.map(([key, value]) => (
                    <Option value={value} key={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex={'none'} xl={{ span: 5 }}>
              <Form.Item>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={async () => {
                    setLoading(true)
                    await onSearch(form.getFieldsValue())
                    setLoading(false)
                  }}
                  icon={<SearchOutlined />}>
                  {'Search'}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    )
  }

  return (
    <>
      <Row gutter={24}>
        <Col span={17} >{searchForm()}</Col>
        <Col span={6} offset={1}>{StaticticsLayout(allData)}</Col>
      </Row>
      <Table rowKey="matchId" columns={columns} dataSource={data} loading={loading} pagination={{ pageSize: 20 }} />
    </>
  )
}

import axios from 'axios'

const getEmVersions = async (isActive, count, sortDesc) => {
  var url = `EmPackage/packageHistories`
  if (isActive !== undefined && isActive != null) {
    url += `?isActive=${isActive}`
  }
  if (count !== undefined && count != null) {
    url += `&count=${count}`
  }
  if (sortDesc !== undefined && sortDesc != null) {
    url += `&sortDesc=${sortDesc}`
  }

  var response = await axios.get(url).catch(console.log)
  return response?.data
}

const getEmPackage = async (id) => {
  try {
    const response = await axios.get(`EmPackage/package?id=${id}`).catch(console.log)
    return response?.data
  } catch (error) {
    return error.response?.data
  }
}

const isEsAccessKeyValid = async (pcId, accessKey) => {
  var response = await axios.get(`EmPackage/verifyEsAccessKey?pcId=${pcId}&accessKey=${accessKey}`).catch(console.log)
  return response?.data
}

const createEmPackage = async (data) => {
  try {
    const response = await axios.post('EmPackage/create', data)
    return response?.data ?? response
  } catch (error) {
    return error.response?.data
  }
}

const updateEmPackage = async (data) => {
  try {
    const response = await axios.put('EmPackage/update', data)
    return response?.data ?? response
  } catch (error) {
    return error.response?.data
  }
}

const deleteEmPackage = async (id) => {
  try {
    const response = await axios.delete(`EmPackage/delete?id=${id}`)
    return response?.data ?? response
  } catch (error) {
    return error.response?.data
  }
}

const getGameOptions = async () => {
  const response = await axios.get('EmPackage/gameOptions').catch(console.log)
  return response?.data ?? response
}

export const EmVersionService = {
  getEmVersions,
  isEsAccessKeyValid,
  createEmPackage,
  updateEmPackage,
  deleteEmPackage,
  getEmPackage,
  getGameOptions
}
